import { Dropdown, Button, Space, Typography, ConfigProvider } from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import IncredableDateFormat from "../common/incredable-date-format";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useState, useContext } from "react";
import { useLocalStorage } from "../common/localStorage";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import {
  PRIVILEGES_CONTENT_DISPLAY_OPTIONS,
  PRIVILEGES_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import usePrivilegesFilter from "../table-filters/privileges-filters";
import { Privileges } from "../redux/api/privileges/types";
import { useDeletePrivilegeMutation } from "../redux/api/privileges/privileges";

export default function PrivilegesList() {
  const [selectedRows, setSelectedRows] = useState<Privileges[]>();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false)
  );
  const {
    TableFilters,
    filteredPrivileges = [],
    UseQueryHookResult: { isFetching, refetch },
  } = usePrivilegesFilter();
  const [deletePrivilege]=useDeletePrivilegeMutation();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Privileges-Table-Preferences",
    PRIVILEGES_TABLE_DEFAULT_PREFERENCES
  );

  const { items, collectionProps, paginationProps } = useCollection(
    filteredPrivileges,
    {
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    }
  );
  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching}
        onSelectionChange={({ detail }) => {
          setSelectedRows([...detail?.selectedItems]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (!!selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem?.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={!!selectedRows ? selectedRows : []}
        columnDefinitions={[
          {
            id: "privilegeType",
            header: "Privilege Type",
            cell: (item) => item?.privilegeType?.name,
            sortingField: "privilegeType",
          },
          {
            id: "name",
            header: "Name",
            cell: (item) => <NavLink to={`/privileges/editprivileges/${item?.id}`}>
              {item?.name}
            </NavLink>,
            sortingField: "name",
          },
          {
            id: "licenseType",
            header: "License Type",
            cell: (item) => item?.licenseType?.name,
            sortingField: "licenseType",
          },
          {
            id: "category",
            header: "Category",
            cell: (item) => item?.category?.name,
          },
          {
            id: "provider",
            header: "Provider",
            cell: (item) =>
              `${item?.provider?.lastName} ${item?.provider?.firstName}`,
            sortingField: "provider",
            sortingComparator: (a, b) =>
              a?.provider?.lastName
                ?.toLowerCase()
                ?.localeCompare(b?.provider?.lastName?.toLowerCase()),
          },
          {
            id: "providerAffiliation",
            header: "Provider Affiliation",
            cell: (item) => (item?.providerAffiliation?.name),
          },
          {
            id: "expirationDate",
            header: "Expiration Date",
            cell: (item) => IncredableDateFormat(item?.expirationDate),
            sortingField: "expirationDate",
          },
          {
            id: "alertDays",
            header: <div>Alert Days</div>,
            cell: (item) => (
              <span>
                {" "}
                {item?.alertDays > 0 ? `${item?.alertDays} days` : "-"}
              </span>
            ),
            sortingField: "alertDays",
          },
          {
            id: "description",
            header: "Description",
            cell: (item) => item?.description,
            sortingField: "description",
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            contentDisplayOptions={PRIVILEGES_CONTENT_DISPLAY_OPTIONS}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        filter={TableFilters}
        header={
          <Header
            counter={`(${filteredPrivileges?.length})`}
            actions={
              <Space size="middle">
                <Button
                  onClick={() => {
                    !!refetch && refetch();
                  }}
                >
                  <ReloadOutlined />
                </Button>
                <Dropdown
                  menu={{
                    disabled: selectedRows?.length !== 1,
                    items: [
                      {
                        label: hasPermission ? "Edit" : "View",
                        key: "edit",
                        icon: <EditOutlined />,
                        onClick: () => {
                          navigate(
                            `/privileges/editprivileges/${selectedRows?.at(0)?.id}`
                          );
                        },
                      },
                      {
                        label: "Delete",
                        key: "delete",
                        icon: <DeleteOutlined />,
                        danger: true,
                        disabled: !hasDeletePermission,
                        onClick: () => {
                          handleOpenDeleteModal();
                        },
                      },
                    ],
                  }}
                >
                  <Button>
                    Actions
                    <DownOutlined />
                  </Button>
                </Dropdown>
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2B6BE6",
                    },
                  }}
                >
                  <Button
                    disabled={!hasPermission}
                    onClick={() => navigate("addprivileges")}
                    type="primary"
                    icon={<PlusOutlined />}
                  >
                    Add Privileges
                  </Button>
                </ConfigProvider>
              </Space>
            }
          >
            Privileges
          </Header>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (
            selectedRows?.length === 1 &&
            selectedRows?.at(0)?.id
          ) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deletePrivilege({
              privilegeId: String(selectedRows?.at(0)?.id),
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Privileges deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Privileges "
        content={
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text>Privilege</Typography.Text>
              <Typography.Text strong style={{ marginTop: "8px" }}>
                {selectedRows?.at(0)?.name}
              </Typography.Text>
            </div>
          </>
        }
        description="Are you sure you want to delete this privilege?"
      />
    </div>
  );
}
