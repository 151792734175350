import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useContext, useState } from "react";
import { ConfigProvider, Dropdown, Space, Typography } from "antd";
import {
  CalendarOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import {
  CONTRACT_TABLE_DEFAULT_PREFERENCES,
  CONTRACT_CONTENT_DISPLAY_OPTIONS,
  TablePreferences,
} from "./table-config";
import useContractFilter from "../table-filters/contract-filters";
import { Contract } from "../redux/api/contracts/types";
import IncredableDateFormat from "../common/incredable-date-format";
import { AsYouType } from "libphonenumber-js";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useDeleteContractMutation } from "../redux/api/contracts/contract";
import IncredableButton from "../components/button";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
export default function ContractV2List() {
  const [selectedRows, setSelectedRows] = useState<Contract[]>();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Contracts-Table-Preferences",
    CONTRACT_TABLE_DEFAULT_PREFERENCES
  );
  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("contract.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("contract.delete") ?? false)
  );
  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };
  const {
    TableFilters,
    filteredContracts = [],
    UseQueryHookResult: { refetch, isFetching, isLoading },
  } = useContractFilter();
  const [deleteContract] = useDeleteContractMutation();
  const { items, collectionProps, paginationProps } = useCollection(
    filteredContracts,
    {
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    }
  );
  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isLoading || isFetching}
        onSelectionChange={({ detail }) => {
          const selectedItem = detail?.selectedItems[0];
          setSelectedRows(selectedItem ? [selectedItem] : []);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={!!selectedRows ? selectedRows : []}
        columnDefinitions={[
          {
            id: "id",
            header: "ID",
            cell: (item) => <span>{item?.id}</span>,
          },
          {
            id: "title",
            header: "Title",
            cell: (item) => (
              <NavLink to={`${item.id}/edit`}>{`${item.title}`}</NavLink>
            ),
            sortingField: "title",
            isRowHeader: true,
          },
          {
            id: "alertDays",
            header: "Alert Days",
            cell: (item) => <div>{item.alertDays}</div>,
          },
          {
            id: "contractType",
            header: "Contract Type",
            cell: (item) => <div>{item?.contractType?.type}</div>,
            sortingField: "contractType.type",
            sortingComparator: (a, b) =>
              (a?.contractType?.type ?? "").localeCompare(
                b?.contractType?.type ?? ""
              ),
          },
          {
            id: "attachmentName",
            header: "Attachment Name",
            cell: (item) => <span>{item.attachment?.name}</span>,
          },
          {
            id: "attachmentSize",
            header: "Attachment Size",
            cell: (item) => <span>{item.attachment?.size}</span>,
          },
          {
            id: "attachmentContentType",
            header: "Attachment ContentType",
            cell: (item) => <span>{item.attachment?.contentType}</span>,
          },
          {
            id: "facilityName",
            header: "Facility Name",
            cell: (item) => <span>{item?.facility?.name}</span>,
            sortingField: "facility.name",
            sortingComparator: (a, b) =>
              (a?.facility?.name ?? "").localeCompare(b?.facility?.name ?? ""),
          },
          {
            id: "facilityNPI",
            header: "Facility NPI",
            cell: (item) => <span>{item?.facility?.npiNumber}</span>,
          },
          {
            id: "facilityDescription",
            header: "Facility Description",
            cell: (item) => <span>{item?.facility?.description}</span>,
          },
          {
            id: "primaryContact",
            header: "Primary Contract",
            cell: (item) => <span>{item?.primaryContact?.email}</span>,
          },
          {
            id: "executionDate",
            header: "Execution Date",
            cell: (item) => (item?.executionDate ?
              <span><CalendarOutlined />{" "}{IncredableDateFormat(item?.executionDate)}</span>
              : "-"
            ),
            sortingField: "executionDate"
          },
          {
            id: "expirationDate",
            header: "Expiration Date",
            cell: (item) => (item?.expirationDate ?
              <span><CalendarOutlined /> {" "}{IncredableDateFormat(item?.expirationDate)}</span>
              : "-"
            ),
            sortingField: "expirationDate"
          },
          {
            id: "lastRenewalDate",
            header: "Last Renewal Date",
            cell: (item) => item?.lastRenewalDate ?
              <span><CalendarOutlined /> {" "}{IncredableDateFormat(item?.lastRenewalDate)}</span>
              : "-",
            sortingField: "lastRenewalDate"
          },
          {
            id: "description",
            header: "Description",
            cell: (item) => <span>{item?.description}</span>,
          },
          {
            id: "entityName",
            header: "Entity Name",
            cell: (item) => <span>{item?.entityName}</span>,
            sortingField: "entityName",
            sortingComparator: (a, b) =>
              (a?.entityName ?? "").localeCompare(
                b?.entityName ?? ""
              ),
          },
          {
            id: "entityPhone",
            header: "Entity Phone",
            cell: (item) => (
              <span>
                {new AsYouType("US").input(item?.entityPhone ?? "") ?? "-"}
              </span>
            ),
          },
          {
            id: "entityEmail",
            header: "Entity Email",
            cell: (item) => <span>{item.entityEmail}</span>,
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            contentDisplayOptions={CONTRACT_CONTENT_DISPLAY_OPTIONS}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        filter={TableFilters}
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <IncredableButton onClick={() => refetch()}>
                    <ReloadOutlined />
                  </IncredableButton>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          icon: <EditOutlined />,
                          onClick: () => {
                            navigate(`${selectedRows?.at(0)?.id}/edit`);
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          disabled: !hasDeletePermission,
                          onClick: () => {
                            handleOpenDeleteModal();
                          },
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <IncredableButton>
                      Actions
                      <DownOutlined />
                    </IncredableButton>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <IncredableButton
                      disabled={!hasPermission}
                      onClick={() => navigate("add")}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Add Contract
                    </IncredableButton>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Contracts{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "18px" }}
                >{`(${filteredContracts?.length})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            try {
              await deleteContract({
                contractId: String(selectedRows?.at(0)?.id),
              });
              toastContext?.openSuccessNotification({
                message: `Contract Deleted Successfully`,
                placement: "topRight",
              });
            } catch {
              toastContext?.openErrorNotification({
                message: "Failed to delete contract",
                placement: "topRight",
              });
            } finally {
              spinnerContext?.hidespinner();
            }
            setSelectedRows([]);
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Contract"
        content={
          <Typography.Text>
            Contract Name:{" "}
            <Typography.Text strong>
              {selectedRows?.at(0)?.title}
            </Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this contract?"
      />
    </div>
  );
}