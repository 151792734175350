import {
  Col,
  Form,
  Row,
  Tabs as AntTabs,
  ConfigProvider,
  Spin,
  Modal,
  Dropdown,
  MenuProps,
  Card,
} from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { ProviderDeaTypeRequest } from "../../redux/api/provider/types";
import {
  useAddProviderDeaMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import { USStatesList } from "../../common/constants";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import StickyFooter from "../../components/StickyFooter";
import ProviderDocumentDetails from "../provider-document-details";
import {
  AllDocumentResponse,
  DocumentRequest,
} from "../../redux/api/document/types";
import { PlusOutlined } from "@ant-design/icons";
import {
  useSaveDocumentMutation,
  useUpdateDocumentMutation,
} from "../../redux/api/document/document";
import { fileAxios } from "../../context/axios";
import { getFileUrl } from "../../config";
import { captureException } from "@sentry/browser";
import ProviderDocumentModal from "../provider-document-modal";
import IncredableButton from "../../components/button";

const items: MenuProps["items"] = [
  {
    key: "add_document",
    label: "Add Document",
  },
  {
    key: "add_alerts",
    label: "Add Alerts",
  },
  {
    key: "automate_psv",
    label: "Add Primary soruce verification",
  },
  {
    key: "request_document",
    label: "Add Request Document",
  },
  {
    key: "request_signature",
    label: "Add Request Signature",
  },
];

export default function EditProviderDEA() {
  const { providerId, deaId } = useParams();
  const methods = useForm<ProviderDeaTypeRequest>();
  const documentMethods = useForm<DocumentRequest & { documentId: string }>();
  const [spinning, setSpinning] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDocumentTab, setShowDocumentTab] = useState<boolean>(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<
    AllDocumentResponse[]
  >([]);
  const [activeKey, setActiveKey] = useState<string>("dea");

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (provider && provider.providerDeas) {
      const dea = provider.providerDeas.find(
        (dea) => dea?.id + "" === deaId + ""
      );
      documentMethods.setValue("providerId", providerId);
      if (!!dea) {
        methods.setValue("id", dea.id);
        methods.setValue("type", dea?.type);
        methods.setValue("notes", dea?.notes);
        methods.setValue("deaNumber", dea?.deaNumber);
        methods.setValue("startDate", dea?.startDate);
        methods.setValue("endDate", dea?.endDate);
        methods.setValue("status", dea?.status);
        methods.setValue("state", dea?.state);
        if (!!dea?.document) {
          documentMethods.setValue("documentId", dea?.document?.id);
          documentMethods.setValue("name", dea?.document?.name);
          documentMethods.setValue("categoryId", dea?.document?.category?.id);
          documentMethods.setValue("notes", dea?.document?.notes);
          documentMethods.setValue(
            "expirationDate",
            dea?.document?.expirationDate
          );
          documentMethods.setValue("alertDays", dea?.document?.alertDays);
        }
      }
    }
  }, [provider, deaId, methods]);

  const [updateProviderDea] = useAddProviderDeaMutation();
  const [saveDocument] = useSaveDocumentMutation();
  const [updateDocument] = useUpdateDocumentMutation();

  const handleExistingDocumentSubmit = (type?: string) => {
    setIsDocumentModalOpen(false);
    setShowDocumentTab(true);
    setActiveKey("document");
    if (type === "linkDocument") {
      documentMethods.setValue("documentId", selectedDocument?.at(0)?.id || "");
      documentMethods.setValue("name", selectedDocument?.at(0)?.name + "");
      documentMethods.setValue(
        "categoryId",
        selectedDocument?.at(0)?.category?.id || ""
      );
      documentMethods.setValue("notes", selectedDocument?.at(0)?.notes);
    } else {
      setSelectedDocument([]);
      documentMethods.setValue("documentId", "");
      setIsDocumentModalOpen(false);
      setShowDocumentTab(true);
      setActiveKey("document");
    }
  };

  return (
    <IncredableContent
      spinning={isLoading || isFetching || spinning}
      style={{ position: "relative", paddingTop: "10px" }}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Provider",
            href: "/manageprovider",
          },
          {
            text: (
              <span onClick={() => navigate(-1)}>{`${
                provider?.firstName || ""
              } ${provider?.lastName || ""}`}</span>
            ),
          },
          {
            text: deaId ? "Edit DEA" : "Add DEA",
            href: "#",
          },
        ],
      }}
    >
      <Spin spinning={spinning} fullscreen />
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#2B6BE6",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "#2B6BE6",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <AntTabs
            defaultActiveKey="dea"
            size="large"
            activeKey={activeKey}
            onChange={(activeKey) => setActiveKey(activeKey)}
            tabBarExtraContent={
              !deaId && (
                <Dropdown
                  menu={{
                    items,
                    onClick: ({ key }) => {
                      if (key === "add_document") setIsDocumentModalOpen(true);
                    },
                  }}
                >
                  <IncredableButton type="primary" icon={<PlusOutlined />}>
                    Extra content
                  </IncredableButton>
                </Dropdown>
              )
            }
            items={[
              {
                label: (
                  <span style={{ fontWeight: "700" }}>
                    {deaId ? "Edit DEA" : "Add DEA"}
                  </span>
                ),
                key: "dea",
                closable: false,
                children: (
                  <FormProvider {...methods}>
                    <Form
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "30px",
                      }}
                      name="editproviderdea"
                      layout="vertical"
                      onFinish={methods.handleSubmit(
                        async (data: ProviderDeaTypeRequest) => {
                          if (!!providerId) {
                            spinnerContext?.showSpinner();
                            await updateProviderDea({
                              ...data,
                              providerId: providerId,
                            });
                            spinnerContext?.hidespinner();
                            toastContext?.openSuccessNotification({
                              message: `DEA saved successfully`,
                              placement: "topRight",
                            });
                            navigate(-1);
                          }
                        }
                      )}
                      autoComplete="off"
                    >
                      <Card>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Type" }}
                              controllerProps={{
                                name: `type`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select Type",
                                options: ["Federal", "State"].map((type) => ({
                                  value: type,
                                })),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFTextField
                              formItemProps={{
                                label:
                                  methods.watch("type") === "State"
                                    ? "CDS Number"
                                    : "DEA Number",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: "deaNumber",
                              }}
                              rules={{
                                required: "This field is required",
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "Status" }}
                              controllerProps={{
                                name: `status`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select Type",
                                options: ["Active", "Inactive"].map(
                                  (status) => ({
                                    value: status,
                                  })
                                ),
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "Start Date",
                              }}
                              controllerProps={{
                                name: `startDate`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFDatePicker
                              formItemProps={{
                                label: "End Date",
                              }}
                              controllerProps={{
                                name: `endDate`,
                              }}
                            />
                          </Col>
                          <Col span={8}>
                            <RHFSelect
                              formItemProps={{ label: "State" }}
                              controllerProps={{
                                name: `state`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Select state",
                                filterOption: (input, option) =>
                                  (option?.value ?? "")
                                    .toString()
                                    .toLowerCase()
                                    .includes(input?.toLowerCase()),
                                options: USStatesList?.map((state) => {
                                  return {
                                    value: state?.value,
                                  };
                                }),
                              }}
                            />
                          </Col>
                          <Col span={24}>
                            <RHFTextArea
                              formItemProps={{ label: "Notes" }}
                              controllerProps={{ name: "notes" }}
                            />
                          </Col>
                        </Row>
                      </Card>
                    </Form>
                  </FormProvider>
                ),
              },
              {
                label: <span style={{ fontWeight: "700" }}>Document</span>,
                key: "document",
                children: (
                  <FormProvider {...documentMethods}>
                    <ConfigProvider
                      theme={{
                        components: {
                          Form: {
                            verticalLabelMargin: -4,
                            itemMarginBottom: 16,
                          },
                        },
                      }}
                    >
                      <Form
                        style={{
                          height: "calc(100vh - 250px)",
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                          paddingBottom: "30px",
                        }}
                        layout="vertical"
                        size="middle"
                        autoComplete="off"
                      >
                        <ProviderDocumentDetails
                          documentId={documentMethods?.getValues("documentId")}
                          uploadedFile={uploadedFile}
                          setUploadedFile={setUploadedFile}
                        />
                      </Form>
                    </ConfigProvider>
                  </FormProvider>
                ),
              },
            ].filter((item) =>
              provider?.providerDeas?.find((dea) => dea?.id + "" == deaId + "")
                ?.document != null
                ? item
                : showDocumentTab
                ? item
                : item.key !== "document"
            )}
          />
          <ProviderDocumentModal
            providerId={providerId}
            isDocumentModalOpen={isDocumentModalOpen}
            setIsDocumentModalOpen={setIsDocumentModalOpen}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
            handleExistingDocumentSubmit={handleExistingDocumentSubmit}
          />
          <StickyFooter
            hasPermission={true}
            onSubmit={async () => {
              const hasDocumentValues =
                documentMethods.getValues("alertDays") ||
                documentMethods.getValues("expirationDate") ||
                documentMethods.getValues("name") ||
                documentMethods.getValues("file");

              const handleProviderDeaSubmit = async (
                documentId?: string
              ): Promise<void> => {
                methods.handleSubmit(async (data: ProviderDeaTypeRequest) => {
                  if (!!providerId) {
                    spinnerContext?.showSpinner();
                    await updateProviderDea({
                      ...data,
                      providerId: providerId,
                      ...(documentId && { documentId }),
                    });
                    spinnerContext?.hidespinner();
                    toastContext?.openSuccessNotification({
                      message: "DEA saved successfully",
                      placement: "topRight",
                    });
                    navigate(-1);
                  }
                })();
              };

              const handleFileUpload = async (
                key: string,
                file?: File
              ): Promise<void> => {
                if (!!file && key) {
                  try {
                    await fileAxios.put(getFileUrl(key), file);
                    toastContext?.openSuccessNotification({
                      message: `Document saved successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                }
              };

              if (!hasDocumentValues) {
                handleProviderDeaSubmit();
              } else {
                const documentId = documentMethods.getValues("documentId");

                const handleDocumentUpdate = async (
                  data: DocumentRequest & { documentId: string }
                ): Promise<void> => {
                  try {
                    const result = await updateDocument({
                      documentId: data.documentId,
                      isVersioned: false,
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderDeaSubmit(result?.id);
                    await handleFileUpload(
                      result?.attachment?.key,
                      uploadedFile
                    );
                    spinnerContext?.hidespinner();
                    toastContext?.openSuccessNotification({
                      message: `Document updated successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    spinnerContext?.hidespinner();
                    toastContext?.openErrorNotification({
                      message: `Document update failed`,
                      placement: "topRight",
                    });
                  }
                };

                const handleDocumentSave = async (
                  data: DocumentRequest
                ): Promise<void> => {
                  try {
                    const documentResponse = await saveDocument({
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderDeaSubmit(documentResponse?.id);
                    await handleFileUpload(
                      documentResponse?.attachment?.key,
                      uploadedFile
                    );
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                };

                documentMethods.handleSubmit(
                  documentId ? handleDocumentUpdate : handleDocumentSave
                )();
              }
            }}
            onCancel={() => {
              navigate(-1);
            }}
          />
        </div>
      </ConfigProvider>
      <Modal
        title="DEA verification Status"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        DEA number <b>AA1019872</b> verified successfully !!
      </Modal>
    </IncredableContent>
  );
}
