import queryString from "query-string";
import { api } from "..";
import {
  Document,
  DocumentHistory,
  DocumentRequest,
  DocumentVersion,
  PaginationDocument,
  Psv,
} from "./types";

const documentAPI = api.injectEndpoints({
  endpoints: (build) => ({
    saveVerfication: build.mutation<
      void,
      { documentId: string; selectedOptionValue: string; license_no: string }
    >({
      query: ({ documentId, selectedOptionValue, ...body }) => {
        return {
          url: `document/${documentId}/psv/auto?taskName=${selectedOptionValue}`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Document"],
    }),
    updateDocument: build.mutation<
      Document,
      {
        documentId: string;
        document: DocumentRequest;
        isVersioned: boolean;
      }
    >({
      query({ documentId, document, isVersioned = false }) {
        return {
          url: `document/${documentId}?isVersioned=${isVersioned}`,
          method: "PUT",
          body: {
            ...document,
            attachment:
              !!document.file && document.file?.size > 0
                ? {
                  name: document.file?.name,
                  size: document.file?.size,
                  contentType: document.file?.type,
                }
                : null,
          },
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
    saveDocument: build.mutation<Document, { document: DocumentRequest }>({
      query({ document }) {
        return {
          url: `/document`,
          method: "POST",
          body: {
            ...document,
            attachment:
              !!document.file && document.file?.size > 0
                ? {
                  name: document.file?.name,
                  size: document.file?.size,
                  contentType: document.file?.type,
                }
                : null,
          },
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
    getAllDocument: build.query<
      PaginationDocument,
      {
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
        name?: string;
        facilityId?: string;
        status?: string;
        categoryId?: string;
        providerId?: string;
        inactiveProvider?: boolean;
      }
    >({
      query: ({
        pageNumber,
        pageSize,
        sortBy,
        direction,
        name,
        facilityId,
        status,
        categoryId,
        providerId,
        inactiveProvider
      }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            sortBy,
            direction,
            name,
            facilityId,
            status,
            categoryId,
            providerId,
            inactiveProvider
          },
          { skipEmptyString: true }
        );
        return `document/all?includeInActive=${inactiveProvider}&${requestParams}`;
      },
      providesTags: ["Document"],
    }),
    getDocument: build.query<Document, { documentId: string }>({
      query: ({ documentId }) => `document/${documentId}`,
      providesTags: (result, error, { documentId }) => [
        { type: "Document", id: documentId },
      ],
    }),
    getAllDocumentPsv: build.query<Psv[], { documentId: string }>({
      query: ({ documentId }) => `document/${documentId}/psv/all`,
      providesTags: ["Document"],
    }),
    getDocumentPsv: build.query<Psv, { documentId: String; psvId: String }>({
      query: ({ documentId, psvId }) => `document/${documentId}/psv/${psvId}`,
      providesTags: ["Document"],
    }),
    getDocumentActivities: build.query<DocumentHistory, { documentId: string }>(
      {
        query: ({ documentId }) => `document/${documentId}/activities`,
        providesTags: ["Document"],
      }
    ),
    getDocumentVersions: build.query<DocumentVersion[], { documentId: string }>(
      {
        query: ({ documentId }) => `document/${documentId}/versions`,
        providesTags: ["Document"],
      }
    ),
    getDocumentVersionById: build.query<
      DocumentVersion,
      { documentId: string; versionId: string }
    >({
      query: ({ documentId, versionId }) =>
        `document/${documentId}/versions/${versionId}`,
      providesTags: ["Document"],
    }),
    deleteDocument: build.mutation<void, { documentId: string }>({
      query({ documentId }) {
        return {
          url: `document/${documentId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Document" }],
    }),
  }),
});

export const {
  useGetAllDocumentQuery,
  useLazyGetAllDocumentQuery,
  useDeleteDocumentMutation,
  useGetAllDocumentPsvQuery,
  useGetDocumentPsvQuery,
  useSaveVerficationMutation,
  useUpdateDocumentMutation,
  useSaveDocumentMutation,
  useGetDocumentQuery,
  useGetDocumentActivitiesQuery,
  useGetDocumentVersionsQuery,
  useGetDocumentVersionByIdQuery,
} = documentAPI;
