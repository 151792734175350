import { Col, Space, Form, Flex, Divider, Row, Modal, Card } from "antd";
import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  ApproveDocumentRequest,
  DocumentRequestResponse,
} from "../../redux/api/documentrequest/types";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useGetAllCategoryQuery } from "../../redux/api/category/category";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import { useUpdatePrivilegeDocumentShareAndMarkAsApproveMutation } from "../../redux/api/privileges/privileges";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { alertReminderOptions } from "../../common/constants";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import PDFTronViewer from "../../components/pdftron-viewer/pdftron-viewer";
import IncredableButton from "../../components/button";
import { useParams } from "react-router-dom";

interface AddNewPrivilegeDocumentModalProps {
  addNewPrivilegeDocumentModal: boolean;
  handleCloseAddNewDocument: () => void;
  document: DocumentRequestResponse | undefined;
}
type FormInputType = ApproveDocumentRequest;
const AddPrivilegeApproveDocument: React.FC<
  AddNewPrivilegeDocumentModalProps
> = ({ addNewPrivilegeDocumentModal, handleCloseAddNewDocument, document }) => {
  const methods = useForm<ApproveDocumentRequest>();
  const { privilegeId } = useParams();
  const spinnerContext = React.useContext(SpinnerContext);
  const toastContext = React.useContext(ToastContext);

  const { data: categories } = useGetAllCategoryQuery();
  const { data: providers } = useGetBasicAllProvidersQuery();

  const [updatePrivilegeDocumentShareAndMarkAsApprove] =
    useUpdatePrivilegeDocumentShareAndMarkAsApproveMutation();

  useEffect(() => {
    if (document) {
      methods.setValue("name", document?.title);
      methods.setValue("categoryId", document?.category?.id);
      methods.setValue("providerId", document?.provider.id);
      methods.setValue("notes", document?.description);
    }
  }, [document]);

  return (
    <Modal
      style={{ top: "15px" }}
      width="85%"
      centered
      open={addNewPrivilegeDocumentModal}
      title="Approve Document Request"
      onCancel={handleCloseAddNewDocument}
      footer={false}
    >
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          name="approveDocumentShareRequest"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            if (document) {
              handleCloseAddNewDocument();
              spinnerContext?.showSpinner();
              if (!!privilegeId)
                await updatePrivilegeDocumentShareAndMarkAsApprove({
                  privilegeId: privilegeId,
                  documentRequestId: document?.id,
                  name: data?.name,
                  expirationDate: data?.expirationDate,
                  alertDays: data?.alertDays,
                  notes: data?.notes,
                  categoryId: data?.categoryId,
                  attachment: document?.attachment,
                });
              spinnerContext?.showSpinner();
              toastContext?.openSuccessNotification({
                message: "Document Request Updated successfully",
                placement: "topRight",
              });
            }
          })}
          autoComplete="off"
        >
          <Divider />
          <Row gutter={16}>
            <Col flex="380px">
              <Card title="Add Document">
                <RHFSelect
                  formItemProps={{ label: "Provider" }}
                  controllerProps={{ name: "providerId" }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    disabled: true,
                    placeholder: "Search to Select",
                    options: providers?.map((provider) => ({
                      label: `${provider.firstName} ${provider.lastName}`,
                      value: provider.id,
                    })),
                  }}
                />

                <RHFTextField
                  formItemProps={{
                    label: "Document Name",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: `name`,
                  }}
                  rules={{ required: "This field is required" }}
                />

                <RHFSelect
                  formItemProps={{ label: "Category" }}
                  controllerProps={{
                    name: `categoryId`,
                  }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    disabled: document?.category ? true : false,
                    placeholder: "Search to Select",
                    options: categories?.map((facility) => {
                      return {
                        label: facility?.name,
                        value: facility?.id,
                      };
                    }),
                  }}
                />

                <RHFDatePicker
                  formItemProps={{
                    label: "Expiration Date",
                  }}
                  controllerProps={{
                    name: `endDate`,
                  }}
                />
                <RHFSelect
                  formItemProps={{ label: "Alert Days" }}
                  controllerProps={{ name: "alertDays" }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    style: { width: "100%" },
                    placeholder: "Search to Select",
                    options: alertReminderOptions?.map((alert) => {
                      return {
                        label: alert?.label,
                        value: alert?.value,
                      };
                    }),
                  }}
                />

                <RHFTextArea
                  formItemProps={{
                    label: "Notes",
                  }}
                  textAreaProps={{}}
                  controllerProps={{
                    name: `description`,
                  }}
                />
              </Card>
            </Col>

            <Col flex="auto">
              <PDFTronViewer
                attachmentKey={document?.attachment?.key}
                replaceFile={undefined}
              />
            </Col>
          </Row>
          <Flex gap="small" wrap justify="end" style={{ marginTop: "15px" }}>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  methods.reset();
                  handleCloseAddNewDocument();
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Approve
              </IncredableButton>
            </Space>
          </Flex>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AddPrivilegeApproveDocument;
