import {
  Col,
  Divider,
  Form,
  Row,
  theme,
  ConfigProvider,
  Tabs as AntTabs,
  Spin,
} from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  useAddProviderPeerReferenceMutation,
  useGetAllDegreeQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";
import { ProviderPeerReferenceRequestType } from "../../redux/api/provider/types";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import { useGetAllSpecialtyQuery } from "../../redux/api/specialty/specialty";
import { Countries, USStatesList } from "../../common/constants";
import {
  AllDocumentResponse,
  DocumentRequest,
} from "../../redux/api/document/types";
import {
  useSaveDocumentMutation,
  useUpdateDocumentMutation,
} from "../../redux/api/document/document";
import ProviderDocumentDetails from "../provider-document-details";
import StickyFooter from "../../components/StickyFooter";
import { fileAxios } from "../../context/axios";
import { getFileUrl } from "../../config";
import { captureException } from "@sentry/browser";
import { PlusOutlined } from "@ant-design/icons";
import ProviderDocumentModal from "../provider-document-modal";
import IncredableButton from "../../components/button";

const { useToken } = theme;

export default function EditProviderPeerReference() {
  const { providerId, peerReferenceId } = useParams();
  const methods = useForm<ProviderPeerReferenceRequestType>();
  const documentMethods = useForm<DocumentRequest & { documentId: string }>();
  const [spinning, setSpinning] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [showDocumentTab, setShowDocumentTab] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>("peerreference");
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<
    AllDocumentResponse[]
  >([]);

  const { data: degree } = useGetAllDegreeQuery();
  const { data: specialty } = useGetAllSpecialtyQuery();
  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { token } = useToken();

  useEffect(() => {
    if (provider && provider.providerPeerReferences) {
      const peerreference = provider?.providerPeerReferences?.find(
        (peerreference) => peerreference?.id + "" === peerReferenceId + ""
      );
      if (peerreference) {
        methods.setValue("id", peerreference.id);
        methods.setValue("name", peerreference.name);
        peerreference.degree?.id &&
          methods.setValue("degreeId", peerreference.degree?.id);
        peerreference.specialty?.id &&
          methods.setValue("specialtyId", peerreference.specialty?.id);
        methods.setValue(
          "timeWithReferenceValue",
          peerreference.timeWithReferenceValue
        );
        methods.setValue(
          "timeWithReferenceUnit",
          peerreference.timeWithReferenceUnit
        );
        methods.setValue("email", peerreference.email);
        methods.setValue("phone", peerreference.phone);
        methods.setValue("fax", peerreference.fax);
        methods.setValue("addressLane1", peerreference.addressLane1);
        methods.setValue("addressLane2", peerreference.addressLane2);
        methods.setValue("city", peerreference.city);
        methods.setValue("state", peerreference.state);
        methods.setValue("zip", peerreference.zip);
        methods.setValue("country", peerreference.country);
        if (!!peerreference?.document) {
          documentMethods.setValue("documentId", peerreference?.document?.id);
          documentMethods.setValue("name", peerreference?.document?.name);
          documentMethods.setValue(
            "categoryId",
            peerreference?.document?.category?.id
          );
          documentMethods.setValue("notes", peerreference?.document?.notes);
          documentMethods.setValue(
            "expirationDate",
            peerreference?.document?.expirationDate
          );
          documentMethods.setValue(
            "alertDays",
            peerreference?.document?.alertDays
          );
        }
      }
    }
  }, [provider, peerReferenceId]);

  const [updateProviderPeerReference] = useAddProviderPeerReferenceMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [saveDocument] = useSaveDocumentMutation();

  const handleExistingDocumentSubmit = (type?: string) => {
    setIsDocumentModalOpen(false);
    setShowDocumentTab(true);
    setActiveKey("document");
    if (type == "linkDocument") {
      documentMethods.setValue("documentId", selectedDocument?.at(0)?.id || "");
      documentMethods.setValue("name", selectedDocument?.at(0)?.name + "");
      documentMethods.setValue(
        "categoryId",
        selectedDocument?.at(0)?.category?.id || ""
      );
      documentMethods.setValue("notes", selectedDocument?.at(0)?.notes);
    } else {
      setSelectedDocument([]);
      documentMethods.setValue("documentId", "");
      setIsDocumentModalOpen(false);
      setShowDocumentTab(true);
      setActiveKey("document");
    }
  };

  return (
    <IncredableContent
      spinning={isLoading || isFetching}
      style={{ position: "relative", paddingTop: "10px" }}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Provider",
            href: "/manageprovider",
          },
          {
            text: (
              <span onClick={() => navigate(-1)}>{`${
                provider?.firstName || ""
              } ${provider?.lastName || ""}`}</span>
            ),
          },
          {
            text: peerReferenceId
              ? "Edit Peer Reference"
              : "Add Peer Reference",
            href: "#",
          },
        ],
      }}
    >
      <Spin spinning={spinning} fullscreen />
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#2B6BE6",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "#2B6BE6",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <AntTabs
            defaultActiveKey="peerreference"
            size="large"
            activeKey={activeKey}
            onChange={(activeKey) => setActiveKey(activeKey)}
            tabBarExtraContent={
              (!peerReferenceId ||
                provider?.providerPeerReferences?.find(
                  (peer) => peer?.id + "" == peerReferenceId + ""
                )?.document === null) && (
                <IncredableButton
                  icon={<PlusOutlined />}
                  onClick={() => setIsDocumentModalOpen(true)}
                >
                  Add Document
                </IncredableButton>
              )
            }
            items={[
              {
                label: (
                  <span style={{ fontWeight: "700" }}>
                    {peerReferenceId
                      ? "Edit Peer Reference"
                      : "Add Peer Reference"}
                  </span>
                ),
                key: "peerreference",
                children: (
                  <FormProvider {...methods}>
                    <Form
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "50px",
                      }}
                      name="editproviderpeerreference"
                      layout="vertical"
                      onFinish={methods.handleSubmit(
                        async (data: ProviderPeerReferenceRequestType) => {
                          if (!!providerId) {
                            spinnerContext?.showSpinner();
                            await updateProviderPeerReference({
                              ...data,
                              providerId: providerId,
                            });
                            spinnerContext?.hidespinner();
                            toastContext?.openSuccessNotification({
                              message: `Peer Reference saved successfully`,
                              placement: "topRight",
                            });
                          }
                          navigate(-1);
                        }
                      )}
                      autoComplete="off"
                    >
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "name",
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Degree" }}
                            controllerProps={{
                              name: "degreeId",
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select degree",
                              options: degree?.map((degree) => {
                                return {
                                  label: degree?.name,
                                  value: degree?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Specialty" }}
                            controllerProps={{
                              name: "specialtyId",
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select specialty",
                              options: specialty?.map((specialty) => {
                                return {
                                  label: specialty?.name,
                                  value: specialty?.id,
                                };
                              }),
                              onModifyClick: () => {
                                navigate("/specialty");
                              },
                            }}
                          />
                        </Col>

                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Time With Reference Value",
                            }}
                            inputProps={{ type: "number" }}
                            controllerProps={{
                              name: "timeWithReferenceValue",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Time With Reference Unit",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "timeWithReferenceUnit",
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider orientation="left">Contact Info</Divider>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Email",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "email",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFPhoneNumber
                            formItemProps={{
                              label: "Phone",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "phone",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Fax",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "fax",
                            }}
                          />
                        </Col>
                      </Row>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Line 1",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "addressLane1",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Line 2",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "addressLane2",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "City",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "city",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "State" }}
                            controllerProps={{
                              name: `state`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select state",
                              filterOption: (input, option) =>
                                (option?.value ?? "")
                                  .toString()
                                  .toLowerCase()
                                  .includes(input?.toLowerCase()),
                              options: USStatesList?.map((state) => {
                                return {
                                  value: state?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Country" }}
                            controllerProps={{
                              name: `country`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select country",
                              filterOption: (input, option) =>
                                (option?.value ?? "")
                                  .toString()
                                  .toLowerCase()
                                  .includes(input?.toLowerCase()),
                              options: Countries?.map((country) => {
                                return {
                                  value: country?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Zip",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "zip",
                            }}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </FormProvider>
                ),
              },
              {
                label: <span style={{ fontWeight: "700" }}>Document</span>,
                key: "document",
                children: (
                  <FormProvider {...documentMethods}>
                    <ConfigProvider
                      theme={{
                        components: {
                          Form: {
                            verticalLabelMargin: -4,
                            itemMarginBottom: 16,
                          },
                        },
                      }}
                    >
                      <Form
                        style={{
                          height: "calc(100vh - 250px)",
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                          paddingBottom: "30px",
                        }}
                        onFinish={documentMethods.handleSubmit(
                          async (data: DocumentRequest) => {
                            console.log("document methods");
                          }
                        )}
                        layout="vertical"
                        size="middle"
                        autoComplete="off"
                      >
                        <ProviderDocumentDetails
                          documentId={documentMethods?.getValues("documentId")}
                          uploadedFile={uploadedFile}
                          setUploadedFile={setUploadedFile}
                        />
                      </Form>
                    </ConfigProvider>
                  </FormProvider>
                ),
              },
            ].filter((item) =>
              provider?.providerPeerReferences?.find(
                (peer) => peer?.id + "" == peerReferenceId + ""
              )?.document != null
                ? item
                : showDocumentTab
                ? item
                : item.key !== "document"
            )}
          />
          <ProviderDocumentModal
            providerId={providerId}
            isDocumentModalOpen={isDocumentModalOpen}
            setIsDocumentModalOpen={setIsDocumentModalOpen}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
            handleExistingDocumentSubmit={handleExistingDocumentSubmit}
          />
          <StickyFooter
            hasPermission={true}
            onSubmit={async () => {
              const hasDocumentValues =
                documentMethods.getValues("alertDays") ||
                documentMethods.getValues("expirationDate") ||
                documentMethods.getValues("name") ||
                documentMethods.getValues("file");

              const handleProviderPeerReferenceSubmit = async (
                documentId?: string
              ): Promise<void> => {
                methods.handleSubmit(
                  async (data: ProviderPeerReferenceRequestType) => {
                    if (!!providerId) {
                      spinnerContext?.showSpinner();
                      await updateProviderPeerReference({
                        ...data,
                        providerId: providerId,
                        ...(documentId && { documentId }),
                      });
                      spinnerContext?.hidespinner();
                      toastContext?.openSuccessNotification({
                        message: "Peer Reference saved successfully",
                        placement: "topRight",
                      });
                      navigate(-1);
                    }
                  }
                )();
              };

              const handleFileUpload = async (
                key: string,
                file?: File
              ): Promise<void> => {
                if (!!file && key) {
                  try {
                    await fileAxios.put(getFileUrl(key), file);
                    toastContext?.openSuccessNotification({
                      message: `Document saved successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                }
              };

              if (!hasDocumentValues) {
                handleProviderPeerReferenceSubmit();
              } else {
                const documentId = documentMethods.getValues("documentId");

                const handleDocumentUpdate = async (
                  data: DocumentRequest & { documentId: string }
                ): Promise<void> => {
                  try {
                    const result = await updateDocument({
                      documentId: data.documentId,
                      isVersioned: false,
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderPeerReferenceSubmit(result?.id);
                    await handleFileUpload(
                      result?.attachment?.key,
                      uploadedFile
                    );
                    spinnerContext?.hidespinner();
                    toastContext?.openSuccessNotification({
                      message: `Document updated successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    spinnerContext?.hidespinner();
                    toastContext?.openErrorNotification({
                      message: `Document update failed`,
                      placement: "topRight",
                    });
                  }
                };

                const handleDocumentSave = async (
                  data: DocumentRequest
                ): Promise<void> => {
                  try {
                    const documentResponse = await saveDocument({
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderPeerReferenceSubmit(
                      documentResponse?.id
                    );
                    await handleFileUpload(
                      documentResponse?.attachment?.key,
                      uploadedFile
                    );
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                };

                documentMethods.handleSubmit(
                  documentId ? handleDocumentUpdate : handleDocumentSave
                )();
              }
            }}
            onCancel={() => {
              navigate(-1);
            }}
          />
        </div>
      </ConfigProvider>
    </IncredableContent>
  );
}
