import { useContext, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { Header, Pagination, StatusIndicator, Table } from "@cloudscape-design/components";
import {
    DeleteOutlined,
    DownOutlined,
    PlusOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { AllDocumentResponse } from "../redux/api/document/types";
import { useLocalStorage } from "../common/localStorage";
import { useDeletePrivilegeDocumentMutation, useGetAllPrivilegeDocumentQuery } from "../redux/api/privileges/privileges";
import { skipToken } from "@reduxjs/toolkit/query";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import IncredableContent from "../components/incredable-content";
import { PRIVILEGEDOCUMENT_CONTENT_DISPLAY_OPTIONS, PRIVILEGEDOCUMENT_TABLE_DEFAULT_PREFERENCES, TablePreferences } from "./table-config";
import PrivilegeAddDocumentsModal from "./addprivilegedocumentmodal";
import IncredableDateFormat from "../common/incredable-date-format";

export default function PrivilegeDocumentList() {
    const [selectedRows, setSelectedRows] = useState<AllDocumentResponse[]>([]);
    const [tablePreferences, setTablePreferences] = useLocalStorage(
        "RD-PrivilegeDocumentList-Table-Preferences",
        PRIVILEGEDOCUMENT_TABLE_DEFAULT_PREFERENCES
    );

    const [isAddDocumentModalVisible, setIsAddDocumentModalVisible] =
        useState<boolean>(false);

    const { privilegeId } = useParams();
    const {
        data: privilegeDocumentsData = [],
        isLoading,
        refetch,
        isFetching,
    } = useGetAllPrivilegeDocumentQuery(
        privilegeId ? { privilegeId: privilegeId } : skipToken
    );
    const [deletePrivilegeDocuments] =
        useDeletePrivilegeDocumentMutation();
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);

    const { items, collectionProps, paginationProps } = useCollection(
        privilegeDocumentsData,
        {
            pagination: { pageSize: tablePreferences.pageSize },
        }
    );

    return (
        <>
            <Table
                loading={isFetching || isLoading}
                onSelectionChange={({ detail }) => {
                    setSelectedRows([...detail?.selectedItems]);
                }}
                onRowClick={(onRowClickDetails) => {
                    const selectedItem = onRowClickDetails?.detail?.item;
                    if (!!selectedItem) {
                        const temp = !!selectedRows ? [...selectedRows] : [];
                        const selectedItemIndex = temp.findIndex(
                            (record) => record?.id + "" === "" + selectedItem?.id
                        );
                        if (selectedItemIndex !== -1) {
                            temp?.splice(selectedItemIndex, 1);
                            setSelectedRows(temp);
                        } else {
                            setSelectedRows([...temp, selectedItem]);
                        }
                    }
                }}
                selectedItems={selectedRows || []}
                columnDefinitions={[
                    {
                        id: "id",
                        header: "Id",
                        cell: (item) => <span>{item?.id}</span>,
                        sortingField: "id",
                    },
                    {
                        id: "documentName",
                        header: "Document Name",
                        cell: (item) => <span>{item?.name}</span>,
                        sortingField: "name",
                        sortingComparator: (a, b) =>
                            (a?.name ?? "").localeCompare(b?.name ?? ""),
                    },
                    {
                        id: "categoryName",
                        header: "Category",
                        cell: (item) => item?.category?.name ?? "-",
                        sortingField: "category.name",
                    },
                    {
                        id: "provider",
                        header: "Provider",
                        cell: (item) =>
                            `${item?.provider?.lastName || ""} ${item?.provider?.firstName || "-"
                            }`,
                        sortingField: "provider.lastName",
                    },
                    {
                        id: "facility",
                        header: "Facility",
                        cell: (item) => item?.facility?.name ?? "-",
                        sortingField: "facility.name",
                    },
                    {
                        id: "fileName",
                        header: "Attachment name",
                        cell: (item) => item?.attachment?.name ?? "-",
                        sortingField: "attachment.name",
                    },
                    {
                        id: "status",
                        header: "Status",
                        cell: (item) =>
                            item?.status ? (
                                <StatusIndicator
                                    colorOverride={
                                        item?.status === "Active"
                                            ? "green"
                                            : item?.status === "Expired"
                                                ? "red"
                                                : "yellow"
                                    }
                                >
                                    {item?.status}
                                </StatusIndicator>
                            ) : (
                                "-"
                            ),
                    },
                    {
                        id: "uploaded",
                        header: "Uploaded Date",
                        sortingField: "createdDate",
                        cell: (item) =>
                            !!item?.createdDate
                                ? IncredableDateFormat(item?.createdDate)
                                : "-",
                    },
                    {
                        id: "alertDays",
                        header: "Alert Days",
                        sortingField: "alertDays",
                        cell: (item) => item?.alertDays,
                    },
                    {
                        id: "expiration",
                        header: "Expiration Date",
                        sortingField: "expirationDate",
                        cell: (item) =>
                            !!item?.expirationDate
                                ? IncredableDateFormat(item?.expirationDate)
                                : "-",
                    },
                    {
                        id: "notes",
                        header: "Notes",
                        sortingField: "notes",
                        cell: (item) => <span>{item.notes}</span>,
                    },
                ]}
                items={items}
                pagination={<Pagination {...paginationProps} />}
                preferences={
                    <TablePreferences
                        preferences={tablePreferences}
                        contentDisplayOptions={
                            PRIVILEGEDOCUMENT_CONTENT_DISPLAY_OPTIONS
                        }
                        setPreferences={(preferences) => {
                            setTablePreferences(preferences);
                        }}
                    />
                }
                loadingText="Loading resources"
                selectionType="multi"
                trackBy="id"
                header={
                    <div style={{ marginTop: "8px", marginBottom: "12px" }}>
                        <Header
                            actions={
                                <Space size="middle">
                                    <Button onClick={() => refetch()}>
                                        <ReloadOutlined />
                                    </Button>
                                    <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    label: "Delete",
                                                    key: "delete",
                                                    icon: <DeleteOutlined />,
                                                    disabled: selectedRows?.length < 1,
                                                    danger: true,
                                                    onClick: async () => {
                                                        if (!!selectedRows && !!privilegeId) {
                                                            console.log("selected Rows::", selectedRows)
                                                            spinnerContext?.showSpinner();
                                                            await deletePrivilegeDocuments({
                                                                privilegeId: privilegeId
                                                                ,
                                                                documents: selectedRows.map((doc) => doc.id),
                                                            });
                                                            spinnerContext?.hidespinner();
                                                            toastContext?.openSuccessNotification({
                                                                message: `privilege document deleted successfully`,
                                                                placement: "topRight",
                                                            });
                                                        }
                                                    },
                                                },
                                            ],
                                        }}
                                    >
                                        <Button>
                                            Actions
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorPrimary: "#2B6BE6",
                                            },
                                        }}
                                    >
                                        <Button
                                            onClick={() => setIsAddDocumentModalVisible(true)}
                                            type="primary"
                                            icon={<PlusOutlined />}
                                        >
                                            Add Document
                                        </Button>
                                    </ConfigProvider>
                                </Space>
                            }
                        >
                            <Typography.Title level={3} style={{ marginTop: 0 }}>
                                Documents{" "}
                                <Typography.Text
                                    type="secondary"
                                    style={{ fontSize: "16px" }}
                                >
                                    ({privilegeDocumentsData?.length})
                                </Typography.Text>
                            </Typography.Title>
                        </Header>
                    </div>
                }
                {...collectionProps}
                columnDisplay={tablePreferences.contentDisplay}
                wrapLines={tablePreferences.wrapLines}
                stripedRows={tablePreferences.stripedRows}
                contentDensity={tablePreferences.contentDensity}
                stickyColumns={tablePreferences.stickyColumns}
            />
            <PrivilegeAddDocumentsModal
                closeModal={() => setIsAddDocumentModalVisible(false)}
                visible={isAddDocumentModalVisible}
            />
        </>
    );
}
