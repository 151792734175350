import { useContext, useState } from "react";
import { Button, ConfigProvider, Flex, Space } from "antd";
import { Pagination, Table, Modal } from "@cloudscape-design/components";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { AllDocumentResponse } from "../redux/api/document/types";
import { useLocalStorage } from "../common/localStorage";
import { useAddPrivilegeDocumentsMutation, useGetPrivilegeQuery } from "../redux/api/privileges/privileges";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { PRIVILEGEDOCUMENT_CONTENT_DISPLAY_OPTIONS, PRIVILEGEDOCUMENT_TABLE_DEFAULT_PREFERENCES, TablePreferences } from "./table-config";
import { useGetAllProviderSafeDocumentsQuery } from "../redux/api/provider/provider";

type PrivilegeAddDocumentsModalProps = {
    closeModal: VoidFunction;
    visible: boolean;
};

export default function PrivilegeAddDocumentsModal({
    visible,
    closeModal,
}: PrivilegeAddDocumentsModalProps) {
    const [selectedRows, setSelectedRows] = useState<AllDocumentResponse[]>([]);

    const [tablePreferences, setTablePreferences] = useLocalStorage(
        "RD-PrivilegesDocumentList-Table-Preferences",
        PRIVILEGEDOCUMENT_TABLE_DEFAULT_PREFERENCES
    );

    const { privilegeId } = useParams();

    const [addPrivilegeDocuments] =
        useAddPrivilegeDocumentsMutation();

    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);

    const { data: privileges, isLoading } = useGetPrivilegeQuery(
        privilegeId ? { privilegeId } : skipToken
    );
    const { data: privilegDocuments } = useGetAllProviderSafeDocumentsQuery(
        !!privileges?.provider?.id
            ? {
                providerId: privileges?.provider?.id,
            }
            : skipToken
    );

    const handleSave = async () => {
        if (!selectedRows || !privilegeId || selectedRows.length === 0) {
            closeModal();
            return;
        }

        try {
            spinnerContext?.showSpinner();
            if (privilegeId)
                await addPrivilegeDocuments({
                    privilegeId: privilegeId,
                    documents: selectedRows.map((doc) => doc.id),
                });

            spinnerContext?.hidespinner();

            toastContext?.openSuccessNotification({
                message: `documents updated successfully`,
                placement: "topRight",
            });
            closeModal();
        } catch (error) {
            spinnerContext?.hidespinner();
            toastContext?.openErrorNotification({
                message: `Error updating documents:`,
                placement: "topRight",
            });
        }
        setSelectedRows([]);
    };

    const { items, collectionProps, paginationProps } = useCollection(
        privilegDocuments?.documents || [],
        {
            pagination: { pageSize: tablePreferences.pageSize },
        }
    );

    return (
        <Modal
            header={"Documents"}
            visible={visible}
            onDismiss={closeModal}
            size="large"
            footer={[
                <Flex justify="end">
                    <Space>
                        <Button
                            onClick={() => {
                                closeModal();
                            }}
                        >
                            Cancel
                        </Button>
                        <ConfigProvider
                            theme={{
                                token: {
                                    colorPrimary: "#2B6BE6",
                                },
                            }}
                        >
                            <Button type="primary" onClick={handleSave}>
                                Save
                            </Button>
                        </ConfigProvider>
                    </Space>
                </Flex>,
            ]}
        >
            <Table
                loading={isLoading}
                onSelectionChange={({ detail }) => {
                    setSelectedRows([...detail?.selectedItems]);
                }}
                onRowClick={(onRowClickDetails) => {
                    const selectedItem = onRowClickDetails?.detail?.item;
                    if (!!selectedItem) {
                        const temp = !!selectedRows ? [...selectedRows] : [];
                        const selectedItemIndex = temp.findIndex(
                            (record) => record?.id + "" === "" + selectedItem?.id
                        );
                        if (selectedItemIndex !== -1) {
                            temp?.splice(selectedItemIndex, 1);
                            setSelectedRows(temp);
                        } else {
                            setSelectedRows([...temp, selectedItem]);
                        }
                    }
                }}
                selectedItems={selectedRows || []}
                columnDefinitions={[
                    {
                        id: "id",
                        header: "Id",
                        cell: (item) => <span>{item?.id}</span>,
                        sortingField: "id",
                    },
                    {
                        id: "documentName",
                        header: "Document Name",
                        cell: (item) => <span>{item?.name}</span>,
                        sortingField: "name",
                        sortingComparator: (a, b) =>
                            (a?.name ?? "").localeCompare(b?.name ?? ""),
                    },
                    {
                        id: "category",
                        header: "Category",
                        cell: (item) => <span>{item?.category?.name}</span>,
                        sortingField: "category",
                        sortingComparator: (a, b) =>
                            (a?.category?.name ?? "").localeCompare(b?.category?.name ?? ""),
                    },
                ]}
                items={items}
                pagination={<Pagination {...paginationProps} />}
                preferences={
                    <TablePreferences
                        preferences={tablePreferences}
                        contentDisplayOptions={
                            PRIVILEGEDOCUMENT_CONTENT_DISPLAY_OPTIONS
                        }
                        setPreferences={(preferences) => {
                            setTablePreferences(preferences);
                        }}
                    />
                }
                loadingText="Loading resources"
                selectionType="multi"
                trackBy="id"
                {...collectionProps}
                columnDisplay={tablePreferences.contentDisplay}
                wrapLines={tablePreferences.wrapLines}
                stripedRows={tablePreferences.stripedRows}
                contentDensity={tablePreferences.contentDensity}
                stickyColumns={tablePreferences.stickyColumns}
            />
        </Modal>
    );
}
