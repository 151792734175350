import queryString from "query-string";
import { api } from "..";
import { DocumentStats, PaginationDocument } from "../document/types";
import { PayerEnrollment } from "../payerenrollment/types";
import {
  AffiliationStatus,
  AffiliationStatusRequest,
  CMECredentialType,
  CMECredentialTypeRequest,
  PaginationProvider,
  PersonalInfoRequestType,
  ProfessionalInfoRequestType,
  ProviderAffiliationTypeRequest,
  ProviderCMETypeRequest,
  ProviderDeaTypeRequest,
  ProviderDisclosureType,
  ProviderEducationRequestType,
  ProviderLiabilityInsuranceRequestType,
  ProviderLicenseRequestType,
  ProviderPeerReferenceRequestType,
  ProviderSpecialtyInfoRequestType,
  ProviderThirdPartyLoginType,
  ProviderWorkHistoryRequestType,
} from "./types";
import {
  Degree,
  ExclusionType,
  LicenseType,
  Provider,
  ProviderRequest,
} from "./types";

const providerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProvider: build.query<Provider, { providerId: string }>({
      query: ({ providerId }) => `provider/${providerId}`,
      providesTags: ["Provider"],
    }),
    getAllProviders: build.query<
      PaginationProvider,
      {
        pageNumber: number;
        pageSize: number;
        name?: string;
        email?: string;
        status?: string;
        facilityId?: string;
        licenseTypeId?: string;
        facilityGroupId?: string;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({
        pageNumber,
        pageSize,
        name,
        email,
        status,
        facilityId,
        licenseTypeId,
        facilityGroupId,
        sortBy,
        direction,
      }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            name,
            email,
            status,
            facilityId,
            licenseTypeId,
            facilityGroupId,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `provider/all?${requestParams}`;
      },
      providesTags: ["Provider"],
    }),
    getExclusions: build.query<ExclusionType[], { npi_number: string }>({
      query: ({ npi_number }) => `verifycomply?npi_number=${npi_number}`,
    }),

    getAllLicenseTypes: build.query<LicenseType[], void>({
      query: () => "licensetype/all",
      providesTags: ["Provider"],
    }),
    getAllDegree: build.query<Degree[], void>({
      query: () => "degree/all",
      providesTags: ["Provider"],
    }),
    getAllProviderSafeDocuments: build.query<
      PaginationDocument,
      { providerId: string }
    >({
      query: ({ providerId }) => `provider/${providerId}/document/all`,
      providesTags: ["Provider", "Document"],
    }),

    getAllProviderEnrollments: build.query<
      PayerEnrollment[],
      { providerId: string }
    >({
      query: ({ providerId }) => `provider/${providerId}/enrollments`,
      providesTags: ["Provider", "PayerEnrollment"],
    }),

    getProfileMap: build.query<
      Map<string, ReadonlyArray<string>>,
      { providerId: string }
    >({
      query: ({ providerId }) => `provider/${providerId}/profile/map`,
    }),

    addProvider: build.mutation<void, ProviderRequest>({
      query: ({ ...body }) => {
        return {
          url: "provider",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Provider"],
    }),

    addProviderProfessionalinfo: build.mutation<
      void,
      { providerId: string } & ProfessionalInfoRequestType
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/professionalinfo`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Provider"],
    }),
    addProviderPersonalInfo: build.mutation<
      void,
      { providerId: string } & PersonalInfoRequestType
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/personalinfo`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Provider"],
    }),

    addProviderEducation: build.mutation<
      void,
      { providerId: string } & ProviderEducationRequestType
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/education`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Provider"],
    }),

    addProviderDisclousres: build.mutation<
      void,
      { providerId: string } & { disclosures: ProviderDisclosureType[] }
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/disclosure`,
          method: "PUT",
          body: body.disclosures,
        };
      },
      invalidatesTags: ["Provider"],
    }),

    deleteProviderEducation: build.mutation<
      void,
      { providerId: string; educationId: string }
    >({
      query: ({ providerId, educationId }) => {
        return {
          url: `provider/${providerId}/education/${educationId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Provider"],
    }),

    updateProvider: build.mutation<
      void,
      Pick<Provider, "id"> & Partial<ProviderRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `provider/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Provider", id: arg.id },
        { type: "Provider" },
      ],
    }),

    postRecord: build.mutation<
      void,
      { providerId: string; recordId?: string; data: any }
    >({
      query({ providerId, ...body }) {
        return {
          url: `provider/${providerId}/profile`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Provider" }],
    }),

    deleteProviderRecord: build.mutation<
      void,
      { providerId: string; recordId: string }
    >({
      query({ providerId, recordId }) {
        return {
          url: `provider/${providerId}/profile/${recordId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Provider" }],
    }),

    deleteProvider: build.mutation<
      void,
      {
        providerId: string;
      }
    >({
      query: ({ providerId }) => ({
        url: `provider/${providerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Provider"],
    }),

    addProviderAffiliation: build.mutation<
      void,
      { providerId: string } & ProviderAffiliationTypeRequest
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/affiliation`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Affiliation", "Provider"],
    }),

    deleteProviderAffiliation: build.mutation<
      void,
      { providerId: string; affiliationId: string }
    >({
      query: ({ providerId, affiliationId }) => {
        return {
          url: `provider/${providerId}/affiliation/${affiliationId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Affiliation", "Provider"],
    }),
    getAllAffiliationStatus: build.query<AffiliationStatus[], void>({
      query: () => `affiliationstatus/all`,
      providesTags: ["AffiliationStatus"],
    }),
    saveAffiliationStatus: build.mutation<void, AffiliationStatusRequest>({
      query: ({ ...body }) => {
        return {
          url: "affiliationstatus",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["AffiliationStatus"],
    }),
    updateAffiliationStatus: build.mutation<
      void,
      { id: string } & Partial<AffiliationStatusRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `affiliationstatus/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "AffiliationStatus", id: arg.id },
        { type: "AffiliationStatus" },
      ],
    }),
    deleteAffiliationStatus: build.mutation<void, { id: string }>({
      query: ({ id }) => {
        return {
          url: `affiliationstatus/${id}`,
          method: "DELETE",
          body: id,
        };
      },
      invalidatesTags: ["AffiliationStatus"],
    }),
    saveAllAffiliationStatus: build.mutation<void, AffiliationStatus[]>({
      query: (types) => {
        return {
          url: `affiliationstatus`,
          method: "POST",
          body: types,
        };
      },
      invalidatesTags: ["Provider"],
    }),
    addProviderDea: build.mutation<
      void,
      { providerId: string } & ProviderDeaTypeRequest
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/dea`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Provider"],
    }),
    deleteProviderDea: build.mutation<
      void,
      { providerId: string; deaId: string }
    >({
      query: ({ providerId, deaId }) => {
        return {
          url: `provider/${providerId}/dea/${deaId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Provider"],
    }),
    addProviderLiabilityInsurance: build.mutation<
      void,
      { providerId: string } & ProviderLiabilityInsuranceRequestType
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/liabilityinsurance`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Provider"],
    }),
    deleteProviderLiabilityInsurance: build.mutation<
      void,
      { providerId: string; liabilityInsuranceId: string }
    >({
      query: ({ providerId, liabilityInsuranceId }) => {
        return {
          url: `provider/${providerId}/liabilityinsurance/${liabilityInsuranceId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Provider"],
    }),
    addProviderLicense: build.mutation<
      void,
      { providerId: string } & ProviderLicenseRequestType
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/license`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Provider"],
    }),
    deleteProviderLicense: build.mutation<
      void,
      { providerId: string; licenseId: string }
    >({
      query: ({ providerId, licenseId }) => {
        return {
          url: `provider/${providerId}/license/${licenseId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Provider"],
    }),
    addProviderPeerReference: build.mutation<
      void,
      { providerId: string } & ProviderPeerReferenceRequestType
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/peerreference`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Provider"],
    }),
    deleteProviderPeerReference: build.mutation<
      void,
      { providerId: string; peerReferenceId: string }
    >({
      query: ({ providerId, peerReferenceId }) => {
        return {
          url: `provider/${providerId}/peerreference/${peerReferenceId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Provider"],
    }),
    addProviderSpecialtyInfo: build.mutation<
      void,
      { providerId: string } & ProviderSpecialtyInfoRequestType
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/specialtyinfo`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Provider"],
    }),
    deleteProviderSpecialtyInfo: build.mutation<
      void,
      { providerId: string; specialtyInfoId: string }
    >({
      query: ({ providerId, specialtyInfoId }) => {
        return {
          url: `provider/${providerId}/specialtyinfo/${specialtyInfoId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Provider"],
    }),
    addProviderThirdPartyLogin: build.mutation<
      void,
      { providerId: string } & ProviderThirdPartyLoginType
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/thirdpartylogin`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Provider"],
    }),
    deleteProviderThirdPartyLogin: build.mutation<
      void,
      { providerId: string; thirdPartyLoginId: string }
    >({
      query: ({ providerId, thirdPartyLoginId }) => {
        return {
          url: `provider/${providerId}/thirdpartylogin/${thirdPartyLoginId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Provider"],
    }),
    addProviderWorkHistory: build.mutation<
      void,
      { providerId: string } & ProviderWorkHistoryRequestType
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/workhistory`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Provider"],
    }),
    deleteProviderWorkHistory: build.mutation<
      void,
      { providerId: string; workHistoryId: string }
    >({
      query: ({ providerId, workHistoryId }) => {
        return {
          url: `provider/${providerId}/workhistory/${workHistoryId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Provider"],
    }),
    activeProvider: build.mutation<void, { providerId: string }>({
      query: ({ providerId }) => {
        return {
          url: `provider/${providerId}/active`,
          method: "POST",
        };
      },
      invalidatesTags: ["Provider"],
    }),
    inActiveProvider: build.mutation<void, { providerId: string }>({
      query: ({ providerId }) => {
        return {
          url: `provider/${providerId}/inactive `,
          method: "POST",
        };
      },
      invalidatesTags: ["Provider"],
    }),
    inActiveAllProvider: build.mutation<void, { data: number[] }>({
      query: ({ data }) => {
        return {
          url: `provider/inactive `,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Provider"],
    }),
    documentstats: build.query<DocumentStats, { providerID: string }>({
      query: ({ providerID }) => {
        return {
          url: `provider/${providerID}/documentstats`,
          method: "GET",
        };
      },
    }),
    getAllDocumentStats: build.query<
      DocumentStats,
      { inactiveProvider?: boolean }
    >({
      query: ({ inactiveProvider = false }) =>
        `document/all/stats?includeInActive=${inactiveProvider}`,
      providesTags: ["Provider", "Document"],
    }),
    getBasicAllProviders: build.query<Provider[], void>({
      query: () => `provider/basic/all`,
      providesTags: ["Provider"],
    }),
    getAllCMECredentialType: build.query<CMECredentialType[], void>({
      query: () => `cmecredentialtype/all`,
      providesTags: ["CMECredentialType"],
    }),
    saveCMECredentialType: build.mutation<void, CMECredentialTypeRequest>({
      query: ({ ...body }) => {
        return {
          url: "cmecredentialtype",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["CMECredentialType"],
    }),
    updateCMECredentialType: build.mutation<
      void,
      { id: string } & Partial<CMECredentialTypeRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `cmecredentialtype/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "CMECredentialType", id: arg.id },
        { type: "CMECredentialType" },
      ],
    }),
    deleteCMECredentialType: build.mutation<void, { id: string }>({
      query: ({ id }) => {
        return {
          url: `cmecredentialtype/${id}`,
          method: "DELETE",
          body: id,
        };
      },
      invalidatesTags: ["CMECredentialType"],
    }),
    addProviderCME: build.mutation<
      void,
      { providerId: string } & ProviderCMETypeRequest
    >({
      query: ({ providerId, ...body }) => {
        return {
          url: `provider/${providerId}/providercme`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["CME", "Provider"],
    }),

    deleteProviderCME: build.mutation<
      void,
      { providerId: string; providerCMEId: string }
    >({
      query: ({ providerId, providerCMEId }) => {
        return {
          url: `provider/${providerId}/providercme/${providerCMEId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["CME", "Provider"],
    }),
    deleteThirdPartySecurityQuestionAnswer: build.mutation<
      void,
      {
        providerId: string;
        thirdPartyLoginId: string;
        securityQuestionId: string;
      }
    >({
      query: ({ providerId, thirdPartyLoginId, securityQuestionId }) => {
        return {
          url: `provider/${providerId}/thirdpartylogin/${thirdPartyLoginId}/securityquestion/${securityQuestionId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Provider"],
    }),
  }),
});

export const {
  useGetProviderQuery,
  useLazyGetAllProvidersQuery,
  useGetAllProvidersQuery,
  useGetExclusionsQuery,
  useGetAllLicenseTypesQuery,
  useGetAllDegreeQuery,
  useGetAllProviderEnrollmentsQuery,
  usePostRecordMutation,
  useDeleteProviderRecordMutation,
  useGetProfileMapQuery,
  useDeleteProviderMutation,
  useAddProviderMutation,
  useUpdateProviderMutation,
  useAddProviderEducationMutation,
  useDeleteProviderEducationMutation,
  useAddProviderDisclousresMutation,
  useAddProviderProfessionalinfoMutation,
  useAddProviderPersonalInfoMutation,
  useAddProviderAffiliationMutation,
  useDeleteProviderAffiliationMutation,
  useSaveAllAffiliationStatusMutation,
  useGetAllAffiliationStatusQuery,
  useSaveAffiliationStatusMutation,
  useUpdateAffiliationStatusMutation,
  useDeleteAffiliationStatusMutation,
  useAddProviderDeaMutation,
  useDeleteProviderDeaMutation,
  useAddProviderLiabilityInsuranceMutation,
  useDeleteProviderLiabilityInsuranceMutation,
  useAddProviderLicenseMutation,
  useDeleteProviderLicenseMutation,
  useAddProviderPeerReferenceMutation,
  useDeleteProviderPeerReferenceMutation,
  useAddProviderSpecialtyInfoMutation,
  useDeleteProviderSpecialtyInfoMutation,
  useAddProviderThirdPartyLoginMutation,
  useDeleteProviderThirdPartyLoginMutation,
  useAddProviderWorkHistoryMutation,
  useDeleteProviderWorkHistoryMutation,
  useGetAllProviderSafeDocumentsQuery,
  useActiveProviderMutation,
  useInActiveProviderMutation,
  useDocumentstatsQuery,
  useGetAllDocumentStatsQuery,
  useGetBasicAllProvidersQuery,
  useInActiveAllProviderMutation,
  useAddProviderCMEMutation,
  useDeleteProviderCMEMutation,
  useGetAllCMECredentialTypeQuery,
  useSaveCMECredentialTypeMutation,
  useUpdateCMECredentialTypeMutation,
  useDeleteCMECredentialTypeMutation,
  useDeleteThirdPartySecurityQuestionAnswerMutation,
} = providerApi;
