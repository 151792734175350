import { Form, Space, Row, Col, Card, ConfigProvider } from "antd";
import IncredableButton from "../../components/button";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetAllCategoryQuery } from "../../redux/api/category/category";
import { FormProvider, useForm } from "react-hook-form";
import { DocumentRequest } from "../../redux/api/document/types";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { useContext, useState } from "react";
import AttachmentViewer from "../../components/attachment-viewer";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useSaveDocumentMutation } from "../../redux/api/document/document";
import { fileAxios } from "../../context/axios";
import { getFileUrl } from "../../config";
import { captureException } from "@sentry/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import { alertReminderOptions } from "../../common/constants";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { BreadcrumbGroup } from "@cloudscape-design/components";

type FormInputType = DocumentRequest;
export default function AddDocument() {
  const methods = useForm<FormInputType>();
  const [error, setError] = useState<string>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const { providerId } = useParams();

  const { data: categories } = useGetAllCategoryQuery();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [saveDocument] = useSaveDocumentMutation();
  const navigate = useNavigate();
  const { data: providers } = useGetBasicAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const location = useLocation();
  let isProvider = location.pathname.includes("manageprovider");

  if (isProvider && isProvider) methods.setValue("providerId", providerId);

  return (
    <div
      style={{
        padding: "12px 24px 12px 24px",
      }}
    >
      <div
        style={{
          padding: "0 0 16px 0",
        }}
      >
        <BreadcrumbGroup
          items={[
            {
              text: isProvider ? "Provider" : "safe",
              href: isProvider ? `/manageprovider/${providerId}` : "/safe",
            },
            { text: "Add", href: "#" },
          ]}
          ariaLabel="Breadcrumbs"
        />
      </div>
      <Row gutter={8}>
        <Col flex="360px">
          <Card title="Add Document">
            <FormProvider {...methods}>
              <ConfigProvider
                theme={{
                  components: {
                    Form: {
                      verticalLabelMargin: -4,
                      itemMarginBottom: 16,
                    },
                  },
                }}
              >
                <Form
                  name="addprovider"
                  layout="vertical"
                  size="middle"
                  onFinish={methods.handleSubmit(
                    async (data: FormInputType) => {
                      if (!data.providerId && !data.facilityId) {
                        toastContext?.openErrorNotification({
                          message: `Provider or Facility is required.`,
                          placement: "top",
                        });
                        return;
                      }
                      if (!uploadedFile) {
                        setError("This field is required");
                      }
                      spinnerContext?.showSpinner();
                      await saveDocument({
                        document: {
                          name: data.name,
                          expirationDate: data.expirationDate,
                          alertDays: data.alertDays,
                          notes: data.notes,
                          file: uploadedFile,
                          categoryId: data.categoryId,
                          facilityId: data.facilityId,
                          providerId: data.providerId,
                        },
                      })
                        .unwrap()
                        .then(async (documentResponse) => {
                          if (
                            !!uploadedFile &&
                            documentResponse?.attachment?.key
                          ) {
                            try {
                              await fileAxios.put(
                                getFileUrl(documentResponse?.attachment?.key),
                                uploadedFile
                              );
                              toastContext?.openSuccessNotification({
                                message: `Document saved successfully`,
                                placement: "topRight",
                              });
                            } catch (error) {
                              captureException(error);
                              toastContext?.openErrorNotification({
                                message: `Failed to save Document`,
                                placement: "topRight",
                              });
                            }
                          } else
                            toastContext?.openSuccessNotification({
                              message: `Document saved successfully`,
                              placement: "topRight",
                            });
                        })
                        .catch((error) => {
                          captureException(error);
                          if (error.status < 500 && error.status >= 400) {
                            toastContext?.openErrorNotification({
                              message: `Failed to save Document`,
                              placement: "topRight",
                            });
                          } else
                            toastContext?.openErrorNotification({
                              message: `Failed to save Document`,
                              placement: "topRight",
                            });
                        });
                      spinnerContext?.hidespinner();
                      navigate(-1);
                    }
                  )}
                  autoComplete="off"
                >
                  <RHFSelect
                    formItemProps={{ label: "Category" }}
                    controllerProps={{
                      name: `categoryId`,
                    }}
                    selectProps={{
                      allowClear: true,
                      showSearch: true,
                      style: { width: "100%" },
                      placeholder: "Search to Category",
                      options: categories?.map((category) => {
                        return {
                          label: category?.name,
                          value: category?.id,
                        };
                      }),
                    }}
                  />

                  <RHFSelect
                    formItemProps={{ label: "Provider" }}
                    controllerProps={{
                      name: `providerId`,
                    }}
                    selectProps={{
                      allowClear: true,
                      showSearch: true,
                      disabled: isProvider ? true : false,
                      style: { width: "100%" },
                      placeholder: "Select provider",
                      options: providers?.map((provider) => {
                        return {
                          label: provider?.firstName + " " + provider?.lastName,
                          value: provider?.id,
                        };
                      }),
                    }}
                  />
                  <RHFSelect
                    formItemProps={{ label: "Facility" }}
                    controllerProps={{
                      name: `facilityId`,
                    }}
                    selectProps={{
                      allowClear: true,
                      showSearch: true,
                      style: { width: "100%" },
                      placeholder: "Select facility",
                      options: facilities?.map((name) => {
                        return {
                          label: name?.name + "",
                          value: name?.id,
                        };
                      }),
                    }}
                  />
                  <RHFTextField
                    formItemProps={{
                      label: "Document Name",
                    }}
                    inputProps={{}}
                    controllerProps={{
                      name: "name",
                    }}
                    rules={{ required: "This field is required" }}
                  />

                  <RHFDatePicker
                    formItemProps={{ label: "Expiration date" }}
                    controllerProps={{ name: "expirationDate" }}
                    datePickerProps={{}}
                  />

                  <RHFSelect
                    formItemProps={{ label: "Expiration Alert Reminder" }}
                    controllerProps={{
                      name: `alertDays`,
                    }}
                    selectProps={{
                      allowClear: true,
                      showSearch: true,
                      style: { width: "100%" },
                      placeholder: "Search to Expiration Alert Reminder",
                      options: alertReminderOptions?.map((alertdays) => {
                        return {
                          label: alertdays?.label,
                          value: alertdays?.value,
                        };
                      }),
                    }}
                  />

                  <RHFTextArea
                    formItemProps={{ label: "Notes" }}
                    controllerProps={{ name: "notes" }}
                  />

                  <Form.Item>
                    <Space style={{ width: "100%", justifyContent: "end" }}>
                      <IncredableButton
                        secondary
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Cancel
                      </IncredableButton>
                      <IncredableButton type="primary" htmlType="submit">
                        Save
                      </IncredableButton>
                    </Space>
                  </Form.Item>
                </Form>
              </ConfigProvider>
            </FormProvider>
          </Card>
        </Col>

        <Col flex="auto">
          <AttachmentViewer
            onFileChange={(file) => {
              setUploadedFile(file);
            }}
            file={uploadedFile}
          />
        </Col>
      </Row>
    </div>
  );
}
