import { useContext, useEffect, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { Facility } from "../redux/api/facility/types";
import { useDeleteFacilityMutation } from "../redux/api/facility/facility";
import {
  FACILITY_CONTENT_DISPLAY_OPTIONS,
  FACILITY_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import useFacilityFilter from "../table-filters/facility-filters";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { AsYouType } from "libphonenumber-js";

export default function FacilityListV2() {
  const [selectedRows, setSelectedRows] = useState<Facility[]>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("facility.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("facility.delete") ?? false)
  );
  const {
    TableFilters,
    filteredFacilities = [],
    UseQueryHookResult: {
      refetch,
      setPageNumber,
      setPageSize,
      pageNumber,
      pageSize,
      isFetching,
      sortingDetails,
      setSortingDetails,
    },
  } = useFacilityFilter();

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Facility-Table-Preferences",
    FACILITY_TABLE_DEFAULT_PREFERENCES
  );

  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const [deleteFacility] = useDeleteFacilityMutation();

  useEffect(() => {
    if (tablePreferences?.pageSize) setPageSize(tablePreferences.pageSize);
  }, [tablePreferences, setPageSize]);

  const { items, collectionProps, paginationProps } = useCollection(
    filteredFacilities,
    {
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    }
  );

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching}
        onSelectionChange={({ detail }) => {
          setSelectedRows([...detail.selectedItems]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem?.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => (
              <NavLink
                to={hasPermission ? `/managefacilities/${item?.id}/edit` : "#"}
              >
                {item?.name}
              </NavLink>
            ),
            sortingField: "name",
            sortingComparator: (a, b) =>
              (a?.name ?? "").localeCompare(b?.name ?? ""),
          },
          {
            id: "npiNumber",
            header: "NPI Number",
            cell: (item) => item?.npiNumber,
            isRowHeader: true,
            sortingField: "npiNumber",
            sortingComparator: (a, b) =>
              (a?.npiNumber ?? "").localeCompare(b?.npiNumber ?? ""),
          },
          {
            id: "facilityType",
            header: "Facility Type",
            cell: (item) => <span>{item?.facilityType?.name ?? "-"}</span>,
            sortingField: "facilityType?.name",
            sortingComparator: (a, b) =>
              (a?.facilityType?.name ?? "").localeCompare(
                b?.facilityType?.name ?? ""
              ),
          },
          {
            id: "taxId",
            header: "Tax ID",
            cell: (item) => <div>{item?.taxId}</div>,
          },
          {
            id: "cliaId",
            header: "CLIA ID",
            cell: (item) => <span>{item?.cliaId}</span>,
          },
          {
            id: "immunizationId",
            header: "Immunization ID",
            cell: (item) => <span>{item?.immunizationId ?? "-"}</span>,
          },
          {
            id: "medicaidId",
            header: "Medicaid",
            cell: (item) => <span>{item?.medicaidId ?? "-"}</span>,
          },
          {
            id: "taxonomyCode",
            header: "Taxonomy Code",
            cell: (item) => <span>{item?.taxonomyCode ?? "-"}</span>,
            sortingField: "taxonomyCode",
            sortingComparator: (a, b) =>
              (a?.taxonomyCode ?? "").localeCompare(b?.taxonomyCode ?? ""),
          },
          {
            id: "phoneNumber",
            header: "Phone Number",
            cell: (item) => (
              <span>
                {new AsYouType("US").input(item?.phoneNumber ?? "") ?? "-"}
              </span>
            ),
          },
          {
            id: "mailingAddress",
            header: "Mailing Address",
            cell: (item) => <span>{item?.email ?? "-"}</span>,
          },
          {
            id: "state",
            header: "State",
            cell: (item) => <span>{item?.facilityAddress?.state ?? "-"}</span>,
            sortingField: "facilityAddress.state",
            sortingComparator: (a, b) =>
              (a?.facilityAddress?.state ?? "").localeCompare(
                b?.facilityAddress?.state ?? ""
              ),
          },
          {
            id: "pocName",
            header: "POC Name",
            cell: (item) => <span>{item?.pocName ?? "-"}</span>,
          },
          {
            id: "pocEmail",
            header: "POC Email",
            cell: (item) => <span>{item?.pocEmail ?? "-"}</span>,
          },
          {
            id: "pocFax",
            header: "POC Fax",
            cell: (item) => <span>{item?.pocFax ?? "-"}</span>,
          },
          {
            id: "notes",
            header: "Notes",
            cell: (item) => <span>{item?.notes ?? "-"}</span>,
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              if (preferences.pageSize !== tablePreferences.pageSize) {
                preferences.pageSize && setPageSize(preferences.pageSize);
              }
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={FACILITY_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        filter={TableFilters}
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          icon: <EditOutlined />,
                          onClick: () => {
                            navigate(`${selectedRows?.at(0)?.id}/edit`);
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => setIsDeleteModalOpen(true),
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      disabled={!hasPermission}
                      onClick={() => navigate(`add`)}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Add Facilities
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Facilities{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${filteredFacilities?.length})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedRows?.at(0)?.id) {
            setIsDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            try {
              await deleteFacility({
                facilityId: String(selectedRows?.at(0)?.id),
              });
              toastContext?.openSuccessNotification({
                message: `Facility deleted successfully`,
                placement: "topRight",
              });
            } catch {
              toastContext?.openErrorNotification({
                message: "Failed to delete facility",
                placement: "topRight",
              });
            } finally {
              spinnerContext?.hidespinner();
            }
            setSelectedRows([]);
          }
        }}
        closeModal={() => setIsDeleteModalOpen(false)}
        header="Delete Facility"
        content={
          <Typography.Text>
            Facility Name:{" "}
            <Typography.Text>{selectedRows?.at(0)?.name}</Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this facility?"
      />
    </div>
  );
}
