import { useContext, useState } from "react";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { FacilityType } from "../redux/api/facility/types";
import { useLocalStorage } from "../common/localStorage";
import { FACILITYTYPE_CONTENT_DISPLAY_OPTIONS, FACILITYTYPE_TABLE_DEFAULT_PREFERENCES, TablePreferences } from "./table-config";
import { useDeleteFacilityTypeMutation, useGetAllFacilityTypesQuery } from "../redux/api/facility/facility";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import IncredableButton from "../components/button";
import IncredableContent from "../components/incredable-content";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";

export default function FacilityTypeList() {
  const [selectedRows, setSelectedRows] = useState<FacilityType[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-FacilityType-Table-Preferences",
    FACILITYTYPE_TABLE_DEFAULT_PREFERENCES
  );

  const {
    data: facilityType = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllFacilityTypesQuery();
  const [deleteFacilityType] = useDeleteFacilityTypeMutation();

  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const { items, collectionProps, paginationProps } = useCollection(
    facilityType,
    {
      pagination: { pageSize: tablePreferences.pageSize },
    }
  );

  return (
    <>
      <IncredableButton
        style={{
          margin: "12px 0 0 32px",
          padding: "12px",
        }}
        secondary
        onClick={() => navigate(-1)}
      >
        Go back
      </IncredableButton>
      <IncredableContent style={{ margin: "12px 32px 0 32px", padding: "0px" }}>
        <Table
          loading={isLoading || isFetching}
          onSelectionChange={({ detail }) => {
            const selectedItem = detail?.selectedItems[0];
            setSelectedRows(selectedItem ? [selectedItem] : []);
          }}
          onRowClick={(onRowClickDetails) => {
            const selectedItem = onRowClickDetails?.detail?.item;
            if (selectedItem) {
              if (selectedRows?.at(0)?.id === selectedItem.id) {
                setSelectedRows([]);
              } else {
                setSelectedRows([selectedItem]);
              }
            }
          }}
          selectedItems={selectedRows || []}
          columnDefinitions={[
            {
              id: "id",
              header: "Id",
              cell: (item) => <span>{item?.id}</span>,
              sortingField: "id",
            },
            {
              id: "name",
              header: "Name",
              cell: (item) => <NavLink to={`${item.id}/edit`}>{item?.name}</NavLink>,
              sortingField: "name",
            },
            {
              id: "description",
              header: "Description",
              cell: (item) => <span>{item?.description}</span>,
              sortingField: "description",
            },
          ]}
          items={items}
          sortingDisabled
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <TablePreferences
              preferences={tablePreferences}
              contentDisplayOptions={FACILITYTYPE_CONTENT_DISPLAY_OPTIONS}
              setPreferences={(preferences) => {
                setTablePreferences(preferences);
              }}
            />
          }
          loadingText="Loading resources"
          selectionType="single"
          trackBy="id"
          header={
            <div style={{ marginTop: "8px", marginBottom: "12px" }}>
              <Header
                actions={
                  <Space size="middle">
                    <Button onClick={() => refetch()}>
                      <ReloadOutlined />
                    </Button>
                    <Dropdown
                      menu={{
                        disabled: selectedRows.length !== 1,
                        items: [
                          {
                            label: "Edit",
                            key: "edit",
                            icon: <EditOutlined />,
                            onClick: () =>
                              navigate(`${selectedRows?.at(0)?.id}/edit`),
                          },
                          {
                            label: "Delete",
                            key: "delete",
                            icon: <DeleteOutlined />,
                            danger: true,
                            onClick: () => setIsDeleteModalOpen(true),
                          },
                        ],
                      }}
                    >
                      <Button>
                        Actions
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#2B6BE6",
                        },
                      }}
                    >
                      <IncredableButton
                        onClick={() => navigate(`add`)}
                        type="primary"
                        icon={<PlusOutlined />}
                        size="middle"
                        style={{
                          backgroundColor: "#598BEB",
                          border: "2px solid #598BEB",
                          boxShadow: "none",
                        }}
                      >
                        Add Facility Type
                      </IncredableButton>
                    </ConfigProvider>
                  </Space>
                }
              >
                <Typography.Title level={3} style={{ marginTop: 0 }}>
                  Facility Types{" "}
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "16px" }}
                  >
                    ({facilityType?.length})
                  </Typography.Text>
                </Typography.Title>
              </Header>
            </div>
          }
          {...collectionProps}
          columnDisplay={tablePreferences.contentDisplay}
          wrapLines={tablePreferences.wrapLines}
          stripedRows={tablePreferences.stripedRows}
          contentDensity={tablePreferences.contentDensity}
          stickyColumns={tablePreferences.stickyColumns}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRows?.at(0)?.id) {
              setIsDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              try {
                await deleteFacilityType({
                  id: String(selectedRows?.at(0)?.id),
                });
                toastContext?.openSuccessNotification({
                  message: `Facility deleted successfully`,
                  placement: "topRight",
                });
              } catch {
                toastContext?.openErrorNotification({
                  message: "Failed to delete facility type",
                  placement: "topRight",
                });
              } finally {
                spinnerContext?.hidespinner();
              }
            }
          }}
          closeModal={() => setIsDeleteModalOpen(false)}
          header="Delete Facility Type"
          content={
            <Typography.Text>
              Type: {""}
              <Typography.Text strong>
                {selectedRows?.at(0)?.name}
              </Typography.Text>
            </Typography.Text>
          }
          description="Are you sure you want to delete this facility type?"
        />
      </IncredableContent>
    </>
  );
}
