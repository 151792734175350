import { useContext, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import {
  DeleteOutlined,
  DownOutlined,
  MinusOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";

import {
  CREDENTIALPACKAGE_DOCUMENTLIST_TABLE_CONTENT_DISPLAY_OPTIONS,
  CREDENTIALPACKAGE_DOCUMENTLIST_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../table-config";

import { useLocalStorage } from "../../common/localStorage";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useCollection } from "@cloudscape-design/collection-hooks";
import IncredableContent from "../../components/incredable-content";
import {
  useDeleteCredentialPackageDocumentsMutation,
  useGetAllCredentialPackageDocumentsQuery,
} from "../../redux/api/credentialpackages/credentialpackages";
import { AllDocumentResponse } from "../../redux/api/document/types";
import { skipToken } from "@reduxjs/toolkit/query";
import CredentialPackageAddDocumentsModal from "./add-credential-package-modal";

export default function DocumentList() {
  const [selectedRows, setSelectedRows] = useState<AllDocumentResponse[]>([]);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-CredentialPackageDocumentList-Table-Preferences",
    CREDENTIALPACKAGE_DOCUMENTLIST_TABLE_DEFAULT_PREFERENCES
  );

  const [isAddDocumentModalVisible, setIsAddDocumentModalVisible] =
    useState<boolean>(false);

  const { id } = useParams();
  const {
    data: packageDocumentsData = [],
    isLoading,
    refetch,
    isFetching,
  } = useGetAllCredentialPackageDocumentsQuery(
    id ? { credentialPackageId: id } : skipToken
  );
  const [deleteCredentialPackageDocuments] =
    useDeleteCredentialPackageDocumentsMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const { items, collectionProps, paginationProps } = useCollection(
    packageDocumentsData,
    {
      pagination: { pageSize: tablePreferences.pageSize },
    }
  );

  return (
    <>
      <IncredableContent
        style={{ margin: "12px 32px 32px 32px", padding: "0px" }}
      >
        <Table
          loading={isFetching || isLoading}
          onSelectionChange={({ detail }) => {
            const selectedItem = detail?.selectedItems[0];
            setSelectedRows(selectedItem ? [selectedItem] : []);
          }}
          onRowClick={(onRowClickDetails) => {
            const selectedItem = onRowClickDetails?.detail?.item;
            if (selectedItem) {
              if (selectedRows?.at(0)?.id === selectedItem.id) {
                setSelectedRows([]);
              } else {
                setSelectedRows([selectedItem]);
              }
            }
          }}
          selectedItems={selectedRows || []}
          columnDefinitions={[
            {
              id: "id",
              header: "Id",
              cell: (item) => <span>{item?.id}</span>,
              sortingField: "id",
            },
            {
              id: "name",
              header: "Document Name",
              cell: (item) => <span>{item?.name}</span>,
              sortingField: "name",
              sortingComparator: (a, b) =>
                (a?.name ?? "").localeCompare(b?.name ?? ""),
            },
            {
              id: "category",
              header: "Category",
              cell: (item) => <span>{item?.category?.name}</span>,
              sortingField: "category",
              sortingComparator: (a, b) =>
                (a?.category?.name ?? "").localeCompare(
                  b?.category?.name ?? ""
                ),
            },
          ]}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <TablePreferences
              preferences={tablePreferences}
              contentDisplayOptions={
                CREDENTIALPACKAGE_DOCUMENTLIST_TABLE_CONTENT_DISPLAY_OPTIONS
              }
              setPreferences={(preferences) => {
                setTablePreferences(preferences);
              }}
            />
          }
          loadingText="Loading resources"
          selectionType="single"
          trackBy="id"
          header={
            <div style={{ marginTop: "8px", marginBottom: "12px" }}>
              <Header
                actions={
                  <Space size="middle">
                    <Button onClick={() => refetch()}>
                      <ReloadOutlined />
                    </Button>
                    <Button
                      disabled={selectedRows?.length === 0}
                      onClick={async () => {
                        if (!!selectedRows && !!id) {
                          spinnerContext?.showSpinner();
                          const docId = selectedRows?.map(
                            (doc) => doc?.id + ""
                          );
                          try {
                            await deleteCredentialPackageDocuments({
                              credentialPackageId: id,
                              documents: docId,
                            });
                            toastContext?.openSuccessNotification({
                              message: `credential package document deleted successfully`,
                              placement: "topRight",
                            });
                          } catch {
                            toastContext?.openErrorNotification({
                              message: "Failed to delete credential package document",
                              placement: "topRight",
                            });
                          } finally {
                            spinnerContext?.hidespinner();
                          }
                        }
                      }}
                      icon={<MinusOutlined />}
                    >
                      Remove
                    </Button>

                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#2B6BE6",
                        },
                      }}
                    >
                      <Button
                        onClick={() => setIsAddDocumentModalVisible(true)}
                        type="primary"
                        icon={<PlusOutlined />}
                      >
                        Add Document
                      </Button>
                    </ConfigProvider>
                  </Space>
                }
              >
                <Typography.Title level={3} style={{ marginTop: 0 }}>
                  Documents{" "}
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "16px" }}
                  >
                    ({packageDocumentsData?.length})
                  </Typography.Text>
                </Typography.Title>
              </Header>
            </div>
          }
          {...collectionProps}
          columnDisplay={tablePreferences.contentDisplay}
          wrapLines={tablePreferences.wrapLines}
          stripedRows={tablePreferences.stripedRows}
          contentDensity={tablePreferences.contentDensity}
          stickyColumns={tablePreferences.stickyColumns}
        />
        <CredentialPackageAddDocumentsModal
          closeModal={() => setIsAddDocumentModalVisible(false)}
          visible={isAddDocumentModalVisible}
        />
      </IncredableContent>
    </>
  );
}
