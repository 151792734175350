import { useContext, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { DeleteOutlined, DownOutlined, EditOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { WorkFlowTask } from "../redux/api/workflow/types";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useGetPrivilegeWorkflowQuery } from "../redux/api/privileges/privileges";
import { skipToken } from "@reduxjs/toolkit/query";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { useLocalStorage } from "../common/localStorage";
import { TASKS_CONTENT_DISPLAY_OPTIONS, TASKS_TABLE_DEFAULT_PREFERENCES } from "../tasks/table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useDeleteTaskMutation } from "../redux/api/task";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import IncredableDateFormat from "../common/incredable-date-format";
import { TablePreferences } from "./table-config";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";

export default function TasksList() {
    const [selectedRows, setSelectedRows] = useState<WorkFlowTask[]>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);
    const navigate = useNavigate();
    const { privilegeId } = useParams();
    const { data: workflow, isLoading, isFetching, refetch } = useGetPrivilegeWorkflowQuery(
        !!privilegeId ? { privilegeId: privilegeId } : skipToken
    );
    const hasPermission = useSelector(
        (state: ApplicationState) =>
            state.ui.is_owner ||
            (state.ui.permissions?.includes("workflow.edit") ?? false)
    );
    const hasDeletePermission = useSelector(
        (state: ApplicationState) =>
            state.ui.is_owner ||
            (state.ui.permissions?.includes("workflow.delete") ?? false)
    );
    const [tablePreferences, setTablePreferences] = useLocalStorage(
        "RD-Tasks-Table-Preferences",
        TASKS_TABLE_DEFAULT_PREFERENCES
    );
    const { items, collectionProps, paginationProps } = useCollection(
        !!workflow ? workflow?.tasks : [],
        {
            pagination: { pageSize: tablePreferences.pageSize },
        }
    );

    const [deleteTask] = useDeleteTaskMutation();
    return (
        <>
            <Table
                loading={isFetching || isLoading}
                onSelectionChange={({ detail }) => {
                    setSelectedRows([...detail.selectedItems]);
                }}
                onRowClick={(onRowClickDetails) => {
                    const selectedItem = onRowClickDetails?.detail?.item;
                    if (selectedItem) {
                        const temp = selectedRows ? [...selectedRows] : [];
                        const selectedItemIndex = temp.findIndex(
                            (record) => record?.id + "" === "" + selectedItem?.id
                        );
                        if (selectedItemIndex !== -1) {
                            temp?.splice(selectedItemIndex, 1);
                            setSelectedRows(temp);
                        } else {
                            setSelectedRows([...temp, selectedItem]);
                        }
                    }
                }}
                selectedItems={selectedRows || []}
                columnDefinitions={[
                    {
                        id: "id",
                        header: <div>Task ID</div>,
                        cell: (item) => <span>{item.id}</span>,
                    },
                    {
                        id: "taskType",
                        header: <div>Task Type</div>,
                        cell: (item) => (<NavLink to={hasPermission ? `task/${item?.id}` : "#"}>
                            {item?.taskType?.name}
                        </NavLink>
                        )
                    },
                    {
                        id: "name",
                        header: <div>Name</div>,
                        cell: (item) => <span>{!!item?.name ? item?.name : "-"}</span>,
                        sortingField: "name",
                        sortingComparator: (a, b) =>
                            (a?.name ?? "").localeCompare(b?.name ?? ""),
                    },
                    {
                        id: "admin",
                        header: <div>Assigned To</div>,
                        cell: (item) => (
                            <span>
                                {!!item?.admin
                                    ? `${item?.admin?.firstName} ${item?.admin?.lastName}`
                                    : "-"}
                            </span>
                        ),
                    },
                    {
                        id: "provider",
                        header: <div>Provider</div>,
                        cell: (item) => (
                            <span>
                                {!!item?.provider
                                    ? `${item?.provider?.firstName} ${item?.provider?.lastName}`
                                    : "-"}
                            </span>
                        ),
                    },
                    {
                        id: "facility",
                        header: <div>Facility</div>,
                        cell: (item) => (
                            <span>{!!item?.facility ? `${item?.facility?.name}` : "-"}</span>
                        ),
                    },
                    {
                        id: "taskStatusId",
                        header: <div>Task Status</div>,
                        cell: (item) => <span>{item?.taskStatus?.name}</span>,
                    },
                    {
                        id: "startDate",
                        header: <div>Start Date</div>,
                        cell: (item) => <span>{IncredableDateFormat(item?.startDate) ?? "-"}</span>,
                    },
                    {
                        id: "dueDate",
                        header: <div>Expiration Date</div>,
                        cell: (item) => <span>{IncredableDateFormat(item?.dueDate) ?? "-"}</span>,
                        sortingField: "dueDate",
                    },
                    {
                        id: "createdDate",
                        header: <div>Created Date</div>,
                        cell: (item) => <span>{IncredableDateFormat(item?.createdDate)}</span>,
                    },
                    {
                        id: "description",
                        header: <div>Notes</div>,
                        cell: (item) => <span>{item?.description}</span>,
                    },
                ]}
                items={items}
                pagination={<Pagination {...paginationProps} />}
                preferences={
                    <TablePreferences
                        preferences={tablePreferences}
                        setPreferences={(preferences) => {
                            setTablePreferences(preferences);
                        }}
                        contentDisplayOptions={TASKS_CONTENT_DISPLAY_OPTIONS}
                    />
                }
                {...collectionProps}
                loadingText="Loading resources"
                selectionType="single"
                trackBy="id"
                header={
                    <div style={{ marginTop: "8px", marginBottom: "12px" }}>
                        <Header
                            actions={
                                <Space size="middle">
                                    <Button
                                        onClick={() => {
                                            !!refetch && refetch();
                                        }}
                                    >
                                        <ReloadOutlined />
                                    </Button>
                                    <Dropdown
                                        menu={{
                                            disabled: selectedRows?.length !== 1,
                                            items: [
                                                {
                                                    label: hasPermission ? "Edit" : "View",
                                                    key: "edit",
                                                    icon: <EditOutlined />,
                                                    onClick: () => {
                                                        navigate(`task/${selectedRows?.at(0)?.id}`);
                                                    },
                                                },
                                                {
                                                    label: "Delete",
                                                    key: "delete",
                                                    onClick: () => setIsDeleteModalOpen(true),
                                                    icon: <DeleteOutlined />,
                                                    disabled: !hasDeletePermission,
                                                    danger: true,
                                                },
                                            ],
                                        }}
                                    >
                                        <Button>
                                            Actions
                                            <DownOutlined />
                                        </Button>
                                    </Dropdown>
                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorPrimary: "#2B6BE6",
                                            },
                                        }}
                                    >
                                        <Button
                                            disabled={!hasPermission}
                                            onClick={() => navigate(`/workflows/${workflow?.id}/addtask`)}
                                            type="primary"
                                            icon={<PlusOutlined />}
                                        >
                                            Add Task
                                        </Button>
                                    </ConfigProvider>
                                </Space>
                            }
                        >
                            <Typography.Title level={3} style={{ marginTop: "0" }}>
                                Tasks{" "}
                                <Typography.Text
                                    type="secondary"
                                    style={{ fontSize: "16px" }}
                                >{`(${workflow?.tasks?.length})`}</Typography.Text>
                            </Typography.Title>
                        </Header>
                    </div>
                }
                columnDisplay={tablePreferences?.contentDisplay}
                wrapLines={tablePreferences?.wrapLines}
                stripedRows={tablePreferences?.stripedRows}
                contentDensity={tablePreferences?.contentDensity}
                stickyColumns={tablePreferences?.stickyColumns}
            />
            <DeleteAlertModal
                visible={isDeleteModalOpen}
                action={async () => {
                    if (selectedRows?.at(0)?.id) {
                        setIsDeleteModalOpen(false);
                        spinnerContext?.showSpinner();
                        await deleteTask({
                            id: String(selectedRows?.at(0)?.id),
                        });
                        spinnerContext?.hidespinner();
                        toastContext?.openSuccessNotification({
                            message: `Task deleted successfully`,
                            placement: "topRight",
                        });
                    }
                }}
                closeModal={() => setIsDeleteModalOpen(false)}
                header="Delete Workflow Task "
                content={
                    <>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Typography.Text>Workflow Task Name</Typography.Text>
                            <Typography.Text strong style={{ marginTop: "8px" }}>
                                {selectedRows?.at(0)?.name}
                            </Typography.Text>
                        </div>
                    </>
                }
                description="Are you sure you want to delete this workflowTask?"
            />
        </>
    );
}
