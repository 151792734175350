import {
  Col,
  Divider,
  Flex,
  Form,
  Row,
  theme,
  Typography,
  Space,
  Switch,
} from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import {
  ProviderThirdPartyLoginType,
  SecurityQuestionAnswer,
} from "../../redux/api/provider/types";
import {
  useAddProviderThirdPartyLoginMutation,
  useDeleteThirdPartySecurityQuestionAnswerMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import { SpaceBetween } from "@cloudscape-design/components";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

const { useToken } = theme;

export default function EditProviderThirdPartyLogin() {
  const { providerId, thirdpartyloginId } = useParams();
  const methods = useForm<ProviderThirdPartyLoginType>();
  const [securityQuestionId, setSecurityQuestionId] =
    useState<SecurityQuestionAnswer[]>();
  const [showSSN, setShowSSN] = useState(false);

  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { token } = useToken();

  const { control } = methods;

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "securityQuestionAnswer",
  });

  useEffect(() => {
    if (provider && provider?.providerThirdPartyLogins) {
      const thirdpartylogin = provider?.providerThirdPartyLogins.find(
        (login) => login?.id + "" === thirdpartyloginId + ""
      );
      setSecurityQuestionId(thirdpartylogin?.securityQuestionAnswer);
      if (!!thirdpartylogin) {
        methods.setValue("id", thirdpartylogin.id);
        methods.setValue("name", thirdpartylogin.name);
        methods.setValue("websiteUrl", thirdpartylogin?.websiteUrl);
        methods.setValue("accountNo", thirdpartylogin.accountNo);
        methods.setValue("username", thirdpartylogin.username);
        methods.setValue("password", thirdpartylogin.password);

        const transformedTasks = thirdpartylogin.securityQuestionAnswer.map(
          (item) => ({
            id: item.id,
            securityQuestion: item.securityQuestion,
            securityAnswer: item.securityAnswer,
          })
        );
        methods.setValue("securityQuestionAnswer", transformedTasks);
      }
    }
  }, [provider, thirdpartyloginId, methods]);

  const hasPermissionForView = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner || (state.ui.permissions?.includes("ssn.view") ?? false)
  );

  const hasPermissionForEdit = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner || (state.ui.permissions?.includes("ssn.edit") ?? false)
  );
  // const showSecrets = () => {
  //   if (hasPermissionForView || hasPermissionForEdit) {
  //     setShowSSN(!showSSN);
  //   } else {
  //     setAlertShow(!alertShow);
  //   }
  // };

  const showSecrets = () => {
    if (hasPermissionForView || hasPermissionForEdit) {
      setShowSSN(!showSSN);
    } else {
      toastContext?.openErrorNotification({
        message: ` You Don't Have A Permission.`,
        placement: "top",
      });
    }
  };

  const [updateProviderThirdPartyLogin] =
    useAddProviderThirdPartyLoginMutation();

  const [deleteThirdPartySecurityQuestionAnswer] =
    useDeleteThirdPartySecurityQuestionAnswerMutation();

  return (
    <IncredableContent
      spinning={isLoading || isFetching}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Provider",
            href: "/manageprovider",
          },
          {
            text: (
              <span onClick={() => navigate(-1)}>{`${
                provider?.firstName || ""
              } ${provider?.lastName || ""}`}</span>
            ),
          },
          {
            text: thirdpartyloginId
              ? "Edit ThirdPartyLogin"
              : "Add ThirdPartyLogin",
            href: "#",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="editproviderthirdpartylogin"
          layout="vertical"
          onFinish={methods.handleSubmit(
            async (data: ProviderThirdPartyLoginType) => {
              if (!!providerId) {
                spinnerContext?.showSpinner();
                await updateProviderThirdPartyLogin({
                  ...data,
                  providerId: providerId,
                  securityQuestionAnswer: data?.securityQuestionAnswer?.map(
                    (item, index) => ({
                      ...item,
                      id: !!thirdpartyloginId
                        ? data.securityQuestionAnswer[index].id
                        : null,
                    })
                  ),
                });
                spinnerContext?.hidespinner();
                toastContext?.openSuccessNotification({
                  message: `Third Party Login saved successfully`,
                  placement: "topRight",
                });
                navigate(-1);
              }
            }
          )}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Third Party Login
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Space direction="vertical" size="middle" style={{ display: "flex" }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={8}>
                <RHFTextField
                  formItemProps={{
                    label: "Name",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: "name",
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFTextField
                  formItemProps={{
                    label: "Account No",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: "accountNo",
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFTextField
                  formItemProps={{
                    label: "Website URL",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: "websiteUrl",
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFTextField
                  formItemProps={{
                    label: "Username",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: "username",
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFTextField
                  formItemProps={{
                    label: "Password",
                  }}
                  inputProps={{
                    type: showSSN ? "text" : "password",
                  }}
                  controllerProps={{
                    name: "password",
                  }}
                />
              </Col>
              <Col style={{ display: "flex", alignItems: "center" }} span={8}>
                <SpaceBetween size="xs" direction="horizontal">
                  <Switch value={showSSN} size="small" onClick={showSecrets} />{" "}
                  Show Password
                </SpaceBetween>
              </Col>
            </Row>
            <Divider orientation="left">Security Info</Divider>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              {fields.map((subfield: any, index: any) => {
                return (
                  <Col span={24} key={subfield.id}>
                    <div style={{ marginTop: "20px" }}>
                      {index > 0 && <Divider />}
                      <Row
                        gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 32,
                        }}
                      >
                        <Col span={10}>
                          <RHFTextField
                            formItemProps={{
                              label: `Security Question ${index + 1}`,
                            }}
                            inputProps={{
                              defaultValue: subfield.securityQuestion || "",
                            }}
                            controllerProps={{
                              name: `securityQuestionAnswer.${index}.securityQuestion`,
                            }}
                          />
                        </Col>
                        <Col span={10}>
                          <RHFTextField
                            formItemProps={{
                              label: `Security Answer ${index + 1}`,
                            }}
                            inputProps={{
                              defaultValue: subfield.securityAnswer || "",
                            }}
                            controllerProps={{
                              name: `securityQuestionAnswer.${index}.securityAnswer`,
                            }}
                          />
                        </Col>
                        <Col
                          span={4}
                          style={{
                            textAlign: "right",
                            marginTop: "25px",
                          }}
                        >
                          <IncredableButton
                            onClick={() => {
                              remove(index);
                              !!securityQuestionId &&
                                deleteThirdPartySecurityQuestionAnswer({
                                  providerId: providerId + "",
                                  thirdPartyLoginId: thirdpartyloginId + "",
                                  securityQuestionId:
                                    securityQuestionId[index]?.id + "",
                                });
                            }}
                            style={{
                              width: "140px",
                              height: "40px",
                              border: "1.5px solid #598BEB",
                              color: "#598BEB",
                              borderRadius: "8px",
                              padding: "10px 18px",
                              fontWeight: 600,
                              fontSize: "16px",
                            }}
                            icon={<MinusCircleOutlined />}
                          >
                            Remove
                          </IncredableButton>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                );
              })}
              <Col span={24}>
                <Divider
                  style={{
                    borderBottom: "1px solid #A884C9",
                  }}
                />
                <Form.Item>
                  <IncredableButton
                    onClick={() =>
                      append({
                        id: null,
                        securityQuestion: "",
                        securityAnswer: "",
                      })
                    }
                    style={{
                      color: token.colorPrimary,
                      borderColor: token.colorPrimary,
                    }}
                    type="dashed"
                    block
                    icon={<PlusOutlined />}
                  >
                    Add More
                  </IncredableButton>
                </Form.Item>
              </Col>
            </Row>
          </Space>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
