import {
  Col,
  Divider,
  Form,
  Row,
  Tabs as AntTabs,
  ConfigProvider,
  Modal,
} from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import {
  AffiliationStatus,
  ProviderAffiliationTypeRequest,
} from "../../redux/api/provider/types";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import {
  useAddProviderAffiliationMutation,
  useGetAllAffiliationStatusQuery,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { useGetAllAffiliationTypeQuery } from "../../redux/api/affiliationType/affiliation";
import IncredableContent from "../../components/incredable-content";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { alertReminderOptions } from "../../common/constants";
import StickyFooter from "../../components/StickyFooter";
import {
  AllDocumentResponse,
  DocumentRequest,
} from "../../redux/api/document/types";
import ProviderDocumentDetails from "../provider-document-details";
import {
  useSaveDocumentMutation,
  useUpdateDocumentMutation,
} from "../../redux/api/document/document";
import { getFileUrl } from "../../config";
import { fileAxios } from "../../context/axios";
import { captureException } from "@sentry/browser";
import ProviderDocumentModal from "../provider-document-modal";
import { PlusOutlined } from "@ant-design/icons";
import IncredableButton from "../../components/button";

export default function EditProviderAffiliation() {
  const { providerId, affiliationId } = useParams();
  const methods = useForm<ProviderAffiliationTypeRequest>();
  const documentMethods = useForm<DocumentRequest & { documentId: string }>();
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const { data: facilities } = useGetFacilitiesQuery();
  const { data: affiliationStatus } = useGetAllAffiliationStatusQuery();
  const { data: affiliationType } = useGetAllAffiliationTypeQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDocumentTab, setShowDocumentTab] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>("affiliation");
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<
    AllDocumentResponse[]
  >([]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const {
    data: provider,
    isFetching,
    isLoading,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (provider && provider.providerAffiliations) {
      const affiliation = provider.providerAffiliations.find(
        (affiliation) => affiliation?.id + "" === affiliationId + ""
      );
      if (!!affiliation) {
        methods.setValue("id", affiliation.id);
        methods.setValue("name", affiliation?.name);
        methods.setValue("startDate", affiliation?.startDate);
        methods.setValue("endDate", affiliation?.endDate);
        methods.setValue("facilityId", affiliation?.facility?.id);
        methods.setValue("isCurrent", affiliation?.isCurrent);
        methods.setValue("affiliationTypeId", affiliation?.affiliationType?.id);
        methods.setValue(
          "affiliationStatusId",
          affiliation?.affiliationStatus?.id
        );
        methods.setValue("alertDays", affiliation?.alertDays);
        methods.setValue("email", affiliation?.email);
        methods.setValue("phone", affiliation?.phone);
        methods.setValue("fax", affiliation?.fax);
        methods.setValue("notes", affiliation?.notes);
        if (!!affiliation?.document) {
          documentMethods.setValue("documentId", affiliation?.document?.id);
          documentMethods.setValue("name", affiliation?.document?.name);
          documentMethods.setValue(
            "categoryId",
            affiliation?.document?.category?.id
          );
          documentMethods.setValue("notes", affiliation?.document?.notes);
          documentMethods.setValue(
            "expirationDate",
            affiliation?.document?.expirationDate
          );
          documentMethods.setValue(
            "alertDays",
            affiliation?.document?.alertDays
          );
        }
      }
    }
  }, [provider, affiliationId]);

  const [updateProviderAffiliation] = useAddProviderAffiliationMutation();
  const [updateDocument] = useUpdateDocumentMutation();
  const [saveDocument] = useSaveDocumentMutation();

  const handleExistingDocumentSubmit = (type?: string) => {
    setIsDocumentModalOpen(false);
    setShowDocumentTab(true);
    setActiveKey("document");
    if (type == "linkDocument") {
      documentMethods.setValue("documentId", selectedDocument?.at(0)?.id || "");
      documentMethods.setValue("name", selectedDocument?.at(0)?.name + "");
      documentMethods.setValue(
        "categoryId",
        selectedDocument?.at(0)?.category?.id || ""
      );
      documentMethods.setValue("notes", selectedDocument?.at(0)?.notes);
    } else {
      setSelectedDocument([]);
      documentMethods.setValue("documentId", "");
      setIsDocumentModalOpen(false);
      setShowDocumentTab(true);
      setActiveKey("document");
    }
  };

  return (
    <IncredableContent
      style={{ position: "relative", paddingTop: "10px" }}
      spinning={isLoading || isFetching}
      breadcrumbGroupProps={{
        items: [
          {
            text: "Provider",
            href: "/manageprovider",
          },
          {
            text: (
              <span onClick={() => navigate(-1)}>{`${
                provider?.firstName || ""
              } ${provider?.lastName || ""}`}</span>
            ),
          },
          {
            text: affiliationId ? "Edit Affiliation" : "Add Affiliation",
            href: "#",
          },
        ],
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              itemSelectedColor: "#2B6BE6",
              cardBg: "#F5F5F5",
              colorBgContainer: "#2B6BE6",
              inkBarColor: "#2B6BE6",
              colorBorderSecondary: "#c6c6cd",
              fontSize: 16,
            },
          },
        }}
      >
        <div
          style={{
            position: "relative",
          }}
        >
          <AntTabs
            defaultActiveKey="affiliation"
            size="large"
            activeKey={activeKey}
            tabBarExtraContent={
              (!affiliationId ||
                provider?.providerAffiliations?.find(
                  (affiliation) => affiliation?.id + "" == affiliationId + ""
                )?.document === null) && (
                <IncredableButton
                  icon={<PlusOutlined />}
                  onClick={() => setIsDocumentModalOpen(true)}
                >
                  Add Document
                </IncredableButton>
              )
            }
            onChange={(activeKey) => setActiveKey(activeKey)}
            items={[
              {
                label: (
                  <span style={{ fontWeight: "700" }}>
                    {affiliationId ? "Edit Affiliation" : "Add Affiliation"}
                  </span>
                ),
                key: "affiliation",
                children: (
                  <FormProvider {...methods}>
                    <Form
                      style={{
                        height: "calc(100vh - 250px)",
                        overflowY: "scroll",
                        scrollbarWidth: "none",
                        paddingBottom: "50px",
                      }}
                      name="editprovideraffiliation"
                      layout="vertical"
                      onFinish={methods.handleSubmit(
                        async (data: ProviderAffiliationTypeRequest) => {
                          if (!!providerId) {
                            spinnerContext?.showSpinner();
                            await updateProviderAffiliation({
                              ...data,
                              providerId: providerId,
                            });
                            spinnerContext?.hidespinner();
                            toastContext?.openSuccessNotification({
                              message: `Affiliation saved successfully`,
                              placement: "topRight",
                            });
                            navigate(-1);
                          }
                        }
                      )}
                      autoComplete="off"
                    >
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Affiliation Type" }}
                            controllerProps={{ name: "affiliationTypeId" }}
                            selectProps={{
                              style: { width: "100%" },
                              placeholder: "Select Affiliation Type",
                              options: affiliationType?.map((type) => ({
                                label: type.name,
                                value: type.id,
                              })),
                              onModifyClick: () => {
                                navigate("/affiliationtype");
                              },
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Affiliation Status" }}
                            controllerProps={{ name: "affiliationStatusId" }}
                            selectProps={{
                              style: { width: "100%" },
                              placeholder: "Select Affiliation Status",
                              options: affiliationStatus?.map(
                                (status: AffiliationStatus) => ({
                                  label: status.name,
                                  value: status.id,
                                })
                              ),
                              onModifyClick: () => {
                                navigate("/affiliationstatus");
                              },
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Facility" }}
                            controllerProps={{ name: "facilityId" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: facilities?.map((facility) => ({
                                label: facility.name,
                                value: facility.id,
                              })),
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{ label: "Start Date" }}
                            controllerProps={{ name: "startDate" }}
                            datePickerProps={{
                              style: { width: "100%" },
                              placeholder: "Select Start Date",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{ label: "End Date" }}
                            controllerProps={{ name: "endDate" }}
                            datePickerProps={{
                              style: { width: "100%" },
                              placeholder: "Select End Date",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Expiration Reminder" }}
                            controllerProps={{ name: "alertDays" }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: alertReminderOptions,
                            }}
                          />
                        </Col>
                      </Row>

                      <Divider orientation="left">Contact Info</Divider>

                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{ label: "Name" }}
                            controllerProps={{ name: "name" }}
                            inputProps={{}}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{ label: "Email" }}
                            controllerProps={{ name: "email" }}
                            inputProps={{}}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFPhoneNumber
                            formItemProps={{ label: "Phone" }}
                            controllerProps={{ name: "phone" }}
                            inputProps={{}}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{ label: "Fax" }}
                            controllerProps={{ name: "fax" }}
                            inputProps={{}}
                          />
                        </Col>
                        <Col span={24}>
                          <RHFTextArea
                            formItemProps={{ label: "Notes" }}
                            controllerProps={{ name: "notes" }}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </FormProvider>
                ),
              },
              {
                label: <span style={{ fontWeight: "700" }}>Document</span>,
                key: "document",
                children: (
                  <FormProvider {...documentMethods}>
                    <ConfigProvider
                      theme={{
                        components: {
                          Form: {
                            verticalLabelMargin: -4,
                            itemMarginBottom: 16,
                          },
                        },
                      }}
                    >
                      <Form
                        style={{
                          height: "calc(100vh - 250px)",
                          overflowY: "scroll",
                          scrollbarWidth: "none",
                          paddingBottom: "30px",
                        }}
                        layout="vertical"
                        size="middle"
                        autoComplete="off"
                      >
                        <ProviderDocumentDetails
                          documentId={documentMethods?.getValues("documentId")}
                          uploadedFile={uploadedFile}
                          setUploadedFile={setUploadedFile}
                        />
                      </Form>
                    </ConfigProvider>
                  </FormProvider>
                ),
              },
            ].filter((item) =>
              provider?.providerAffiliations?.find(
                (affiliation) => affiliation?.id + "" == affiliationId + ""
              )?.document != null
                ? item
                : showDocumentTab
                ? item
                : item.key !== "document"
            )}
          />
          <ProviderDocumentModal
            providerId={providerId}
            isDocumentModalOpen={isDocumentModalOpen}
            setIsDocumentModalOpen={setIsDocumentModalOpen}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
            handleExistingDocumentSubmit={handleExistingDocumentSubmit}
          />
          <StickyFooter
            hasPermission={true}
            onSubmit={async () => {
              const hasDocumentValues =
                documentMethods.getValues("alertDays") ||
                documentMethods.getValues("expirationDate") ||
                documentMethods.getValues("name") ||
                documentMethods.getValues("file");

              const handleProviderAffiliationSubmit = async (
                documentId?: string
              ): Promise<void> => {
                methods.handleSubmit(
                  async (data: ProviderAffiliationTypeRequest) => {
                    if (!!providerId) {
                      spinnerContext?.showSpinner();
                      await updateProviderAffiliation({
                        ...data,
                        providerId: providerId,
                        ...(documentId && { documentId }),
                      });
                      spinnerContext?.hidespinner();
                      toastContext?.openSuccessNotification({
                        message: "Affiliation saved successfully",
                        placement: "topRight",
                      });
                      navigate(-1);
                    }
                  }
                )();
              };

              const handleFileUpload = async (
                key: string,
                file?: File
              ): Promise<void> => {
                if (!!file && key) {
                  try {
                    await fileAxios.put(getFileUrl(key), file);
                    toastContext?.openSuccessNotification({
                      message: `Document saved successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                }
              };

              if (!hasDocumentValues) {
                handleProviderAffiliationSubmit();
              } else {
                const documentId = documentMethods.getValues("documentId");

                const handleDocumentUpdate = async (
                  data: DocumentRequest & { documentId: string }
                ): Promise<void> => {
                  try {
                    const result = await updateDocument({
                      documentId: data.documentId,
                      isVersioned: false,
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderAffiliationSubmit(result?.id);
                    await handleFileUpload(
                      result?.attachment?.key,
                      uploadedFile
                    );
                    spinnerContext?.hidespinner();
                    toastContext?.openSuccessNotification({
                      message: `Document updated successfully`,
                      placement: "topRight",
                    });
                  } catch (error) {
                    spinnerContext?.hidespinner();
                    toastContext?.openErrorNotification({
                      message: `Document update failed`,
                      placement: "topRight",
                    });
                  }
                };

                const handleDocumentSave = async (
                  data: DocumentRequest
                ): Promise<void> => {
                  try {
                    const documentResponse = await saveDocument({
                      document: {
                        ...data,
                        file: uploadedFile,
                        providerId,
                      },
                    }).unwrap();

                    await handleProviderAffiliationSubmit(documentResponse?.id);
                    await handleFileUpload(
                      documentResponse?.attachment?.key,
                      uploadedFile
                    );
                  } catch (error) {
                    captureException(error);
                    toastContext?.openErrorNotification({
                      message: `Failed to save Document`,
                      placement: "topRight",
                    });
                  }
                };

                documentMethods.handleSubmit(
                  documentId ? handleDocumentUpdate : handleDocumentSave
                )();
              }
            }}
            onCancel={() => {
              navigate(-1);
            }}
          />
        </div>
      </ConfigProvider>
      <Modal
        title="Affiliation verification Status"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        Affiliation number <b>AA1019872</b> verified successfully !!
      </Modal>
    </IncredableContent>
  );
}
