import {
  Card,
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import IncredableContent from "../../components/incredable-content";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { WorkflowRequest } from "../../redux/api/workflow/types";
import {
  useAddWorkflowMutation,
  useGetAllWorkflowStatusQuery,
  useGetAllWorkflowTemplateQuery,
} from "../../redux/api/workflow/workflow";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import IncredableButton from "../../components/button";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import StepThree from "./step-three";

export default function AddWorkflowV2() {
  const methods = useForm<WorkflowRequest>();
  const [formData, setFormData] = useState<WorkflowRequest>();

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("workflow.edit") ?? false)
  );
  const [addWorkflow] = useAddWorkflowMutation();
  const { data: members = [] } = useGetAllMembersQuery();
  const { data: workflowStatuses, isLoading } = useGetAllWorkflowStatusQuery();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const { data: providers = [] } = useGetBasicAllProvidersQuery();
  const { data: facilities } = useGetFacilitiesQuery();
  const { data: workflowTemplates } = useGetAllWorkflowTemplateQuery();

  const onSubmit = async (data: WorkflowRequest) => {
    spinnerContext?.showSpinner();
    await addWorkflow({ ...data });
    spinnerContext?.hidespinner();
    toastContext?.openSuccessNotification({
      message: `workflow updated successfully`,
      placement: "topRight",
      onClose: () => {
        navigate(-1);
      },
    });
  };

  return (
    <>
      <IncredableContent
        spinning={isLoading}
        breadcrumbGroupProps={{
          items: [
            {
              text: "Workflow",
              href: "/Workflows",
            },
            {
              text: "Add",
              href: "#",
            },
          ],
        }}
      >
        <FormProvider {...methods}>
          <Form layout="vertical">
            <Flex gap="small" wrap justify="space-between">
              <Typography.Text
                strong
                style={{
                  fontSize: "22px",
                  color: "#1D1A1F",
                  lineHeight: "25px",
                  fontWeight: "700",
                }}
              >
                Add Workflow
              </Typography.Text>
              <Space>
                <IncredableButton
                  secondary
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </IncredableButton>
                <IncredableButton
                  disabled={!hasPermission}
                  type="primary"
                  onClick={methods.handleSubmit(onSubmit)}
                >
                  Save
                </IncredableButton>
              </Space>
            </Flex>
            <Divider />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={8}>
                <RHFTextField
                  formItemProps={{
                    label: "Workflow Name",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: `name`,
                  }}
                  rules={{ required: "This field is required" }}
                />
              </Col>
              <Col span={8}>
                <RHFSelect
                  formItemProps={{
                    label: "Workflow Status",
                  }}
                  controllerProps={{
                    name: "workflowStatusId",
                  }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    placeholder: "Select Workflow Status",
                    options: workflowStatuses?.map((status) => {
                      return {
                        label: status?.name,
                        value: status?.id,
                      };
                    }),
                    onModifyClick: () => {
                      navigate("/workflowstatus");
                    },
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFSelect
                  formItemProps={{ label: "Assigned To" }}
                  controllerProps={{
                    name: "adminId",
                  }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    placeholder: "",
                    options: members?.map((member) => {
                      return {
                        label: `${member?.firstName} ${member?.lastName}`,
                        value: member?.id,
                      };
                    }),
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFSelect
                  formItemProps={{ label: "Provider" }}
                  controllerProps={{
                    name: `providerId`,
                  }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    placeholder: "Search to Select",
                    options: providers?.map((provider) => {
                      return {
                        label: `${provider?.firstName} ${provider?.lastName}`,
                        value: provider?.id,
                      };
                    }),
                  }}
                  rules={{ required: "This field is required" }}
                />
              </Col>
              <Col span={8}>
                <RHFSelect
                  formItemProps={{ label: "Workflow Template" }}
                  controllerProps={{
                    name: "workflowTemplateId",
                  }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    placeholder: "",
                    options: workflowTemplates?.map((workflowtemplate) => ({
                      label: workflowtemplate?.name,
                      value: workflowtemplate?.id,
                    })),
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFSelect
                  formItemProps={{ label: "Facility" }}
                  controllerProps={{
                    name: "facilityId",
                  }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    placeholder: "Search to Select",
                    options: facilities?.map((facility) => ({
                      label: facility.name,
                      value: facility.id,
                    })),
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFDatePicker
                  formItemProps={{
                    label: "Start Date",
                  }}
                  controllerProps={{
                    name: "startDate",
                  }}
                  datePickerProps={{
                    placeholder: "Select Start Date",
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFDatePicker
                  formItemProps={{
                    label: "Due Date",
                  }}
                  controllerProps={{
                    name: "dueDate",
                  }}
                  datePickerProps={{
                    placeholder: "Select Due Date",
                  }}
                />
              </Col>
              <Col span={8}>
                <RHFDatePicker
                  formItemProps={{
                    label: "Completed Date",
                  }}
                  controllerProps={{
                    name: "completedDate",
                  }}
                  datePickerProps={{
                    placeholder: "Completed Date",
                  }}
                />
              </Col>
              <Col span={24}>
                <RHFTextArea
                  formItemProps={{ label: "Notes" }}
                  controllerProps={{ name: "notes" }}
                />
              </Col>
            </Row>
            <Divider orientation="left">Tasks</Divider>
            <StepThree
              workflowTemplateId={formData?.workflowTemplateId}
              methods={methods}
            />
          </Form>
        </FormProvider>
      </IncredableContent>
    </>
  );
}
