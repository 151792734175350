import React, { useContext, useState } from "react";
import {
  Header,
  Pagination,
  StatusIndicator,
  Table,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import {
  Button,
  ConfigProvider,
  Dropdown,
  Flex,
  Space,
  Typography,
  Modal as AntdModal,
} from "antd";
import {
  CheckSquareOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { NavLink, useParams } from "react-router-dom";
import { DocumentRequestResponse } from "../redux/api/documentrequest/types";
import { useLocalStorage } from "../common/localStorage";
import {
  DocumentRequest_CONTENT_DISPLAY_OPTIONS,
  DocumentRequest_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../provider/table-config";
import { Attachment } from "../redux/api/document/types";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import IncredableButton from "../components/button";
import EditDocumentRequestList from "../requests/document-requests/modify/edit-document-requestmodal";
import AddNewDocument from "../requests/document-requests/modify/add-new-document";
import PDFTronViewer from "../components/pdftron-viewer/pdftron-viewer";
import TableStats from "../components/table-stats";
import usePrivilegeDocumentRequestFilter from "../table-filters/privilege-document-request-filters";
import { useGetAllPrivilegeDocumentRequestStatsQuery, useUpdatePrivilegeDocumentShareAndMarkAsDeniedMutation } from "../redux/api/privileges/privileges";
import { skipToken } from "@reduxjs/toolkit/query";
import AddPrivilegeApproveDocument from "./modify/add-privilegeApproveDocumentModal";
import EditPrivilegeDocumentRequestList from "./modify/edit-privilegeDocumentRequestList";

export default function PrivilegeDocumentRequest() {
  const { privilegeId } = useParams();
  const [selectedRows, setSelectedRows] = useState<DocumentRequestResponse[]>();
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-DocumentRequest-Table-Preferences",
    DocumentRequest_TABLE_DEFAULT_PREFERENCES
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [selectedAttachment, setSelectedAttachment] = useState<
    Attachment | undefined
  >();
  const [denyModal, setDenyModal] = useState<boolean>(false);

  const [editDocumentRequest, setEditDocumentRequest] =
    useState<boolean>(false);

  const [addNewDocumentModal, setAddNewDocumentModal] =
    useState<boolean>(false);

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const {
    TableFilters,
    filteredPrivilegeDocumentRequest = [],
    UseQueryHookResult: { refetch, isFetching, isLoading },
  } = usePrivilegeDocumentRequestFilter();

  const { data: documentStats } = useGetAllPrivilegeDocumentRequestStatsQuery(
    privilegeId ? { privilegeId } : skipToken,
  );

  const [updatePrivilegeDocumentShareAndMarkAsDenied] =
    useUpdatePrivilegeDocumentShareAndMarkAsDeniedMutation();

  const { items, collectionProps, paginationProps } = useCollection(
    filteredPrivilegeDocumentRequest,
    {
      pagination: { pageSize: tablePreferences.pageSize },
    }
  );

  const handleCloseAddNewDocument = () => {
    setAddNewDocumentModal(false);
  };

  const handleOpenEditDocumentRequest = () => {
    setDenyModal(false);
    setEditDocumentRequest(true);
  };

  const handleCloseEditDocumentRequest = () => {
    setEditDocumentRequest(false);
  };

  const handleAttachmentClick = (
    attachment: React.SetStateAction<Attachment | undefined>
  ) => {
    setSelectedAttachment(attachment);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedAttachment(undefined);
  };

  const handleDenyButton = async (privilegeId: string, documentRequestId: string) => {
    if (privilegeId && documentRequestId) {
      setDenyModal(false);
      spinnerContext?.showSpinner();
      handleCloseEditDocumentRequest();
      await updatePrivilegeDocumentShareAndMarkAsDenied({ privilegeId, documentRequestId });
      spinnerContext?.showSpinner();
      toastContext?.openSuccessNotification({
        message: "Document Share Request Updated successfully",
        placement: "topRight",
      });
    }
  };

  return (
    <div>
      <SpaceBetween direction="vertical" size="l">
        <TableStats
          stats={[
            { label: "Approved", count: documentStats?.approved.toString() || "0", color: "#3f8600" },
            { label: "Pending", count: documentStats?.pending?.toString() || "0", color: "#FFBF00" },
            { label: "To be reviewed", count: documentStats?.toBeReviewed?.toString() || "0", color: "#cf1322" },
            {
              label: "Denied", count: documentStats?.denied?.toString() || "0", color: "#b4b4bb"
            },
          ]}
        />
        <Table
          loading={isLoading || isFetching}
          onSelectionChange={({ detail }) => {
            setSelectedRows([...detail?.selectedItems]);
          }}
          onRowClick={(onRowClickDetails) => {
            const selectedItem = onRowClickDetails?.detail?.item;
            if (selectedItem) {
              if (selectedRows?.at(0)?.id === selectedItem?.id) {
                setSelectedRows([]);
              } else {
                setSelectedRows([selectedItem]);
              }
            }
          }}
          selectedItems={selectedRows || []}
          columnDefinitions={[
            {
              id: "title",
              header: "Title",
              cell: (item) => item.title,
              sortingField: "title",
              isRowHeader: true,
            },
            {
              id: "description",
              header: "Description",
              cell: (item) => item?.description,
              sortingField: "description",
            },
            {
              id: "provider",
              header: "Provider",
              cell: (item) =>
                item?.provider?.lastName + " " + item?.provider?.firstName,
              sortingField: "provider",
            },
            {
              id: "attachment",
              header: "Attachment",
              cell: (item) =>
                item?.attachment?.name ? (
                  <a onClick={() => handleAttachmentClick(item.attachment)}>
                    {item.attachment.name}
                  </a>
                ) : (
                  "-"
                ),
              sortingField: "attachment",
            },
            {
              id: "category",
              header: "Category",
              cell: (item) => item?.category?.name || "-",
            },
            {
              id: "status",
              header: <div>Status</div>,
              cell: (item) => (
                <StatusIndicator
                  type={
                    item?.status === "to_be_reviewed"
                      ? "warning"
                      : item?.status === "pending"
                        ? "in-progress"
                        : item?.status === "denied"
                          ? "stopped"
                          : "success"
                  }
                  colorOverride={item?.status === "denied" ? "red" : undefined}
                >
                  {item?.status === "to_be_reviewed"
                    ? "To be reviewed"
                    : item?.status === "pending"
                      ? "Pending"
                      : item?.status === "denied"
                        ? "Denied"
                        : "Approved"}
                </StatusIndicator>
              ),
            },
          ]}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <TablePreferences
              preferences={tablePreferences}
              contentDisplayOptions={DocumentRequest_CONTENT_DISPLAY_OPTIONS}
              setPreferences={(preferences) => {
                setTablePreferences(preferences);
              }}
            />
          }
          loadingText="Loading resources"
          selectionType="single"
          trackBy="id"
          filter={TableFilters}
          header={
            <div style={{ marginTop: "8px", marginBottom: "12px" }}>
              <Header
                actions={
                  <Space size="middle">
                    <Button onClick={() => refetch()}>
                      <ReloadOutlined />
                    </Button>
                    <Dropdown
                      // disabled={
                      //   selectedRows?.at(0)?.status === "pending"
                      //     ? true
                      //     : selectedRows?.at(0)?.status === "denied"
                      //       ? true
                      //       : selectedRows?.at(0)?.status === "approved"
                      // }
                      menu={{
                        items: [
                          {
                            label: "Approve",
                            key: "approve",
                            disabled: selectedRows?.length !== 1,
                            icon: <CheckSquareOutlined />,
                            onClick: () => {
                              setAddNewDocumentModal(true);
                            },
                          },
                          {
                            label: "Deny",
                            key: "deny",
                            disabled: selectedRows?.length !== 1,
                            icon: <EditOutlined />,
                            onClick: () => {
                              setDenyModal(true);
                            },
                            danger: true,
                          },
                        ],
                      }}
                    >
                      <Button>
                        Actions
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#2B6BE6",
                        },
                      }}
                    >
                      <Button type="primary" icon={<PlusOutlined />}>
                        <NavLink to={"addDocumentRequest"}>Request Document</NavLink>
                      </Button>
                    </ConfigProvider>
                  </Space>
                }
              >
                <Typography.Title level={3} style={{ marginTop: "0" }}>
                  Requested Documents{" "}
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "18px" }}
                  >{`(${filteredPrivilegeDocumentRequest?.length})`}</Typography.Text>
                </Typography.Title>
              </Header>
            </div>
          }
          {...collectionProps}
          columnDisplay={tablePreferences?.contentDisplay}
          wrapLines={tablePreferences?.wrapLines}
          stripedRows={tablePreferences?.stripedRows}
          contentDensity={tablePreferences?.contentDensity}
          stickyColumns={tablePreferences?.stickyColumns}
        />
      </SpaceBetween>
      {/* for deny */}
      <AntdModal
        open={denyModal}
        onCancel={() => setDenyModal(false)}
        title="Document Request"
        footer={false}
      >
        <Space direction="vertical" style={{ marginBottom: "10px" }}>
          <Typography.Text>
            Document Title:{" "}
            <Typography.Text strong>
              {selectedRows?.at(0)?.title}
            </Typography.Text>
          </Typography.Text>
          Are you sure you want to deny this document request?
        </Space>
        <Flex gap="small" wrap justify="end">
          <Space>
            <IncredableButton
              secondary
              onClick={() => {
                setDenyModal(false);
              }}
            >
              Cancel
            </IncredableButton>
            <IncredableButton
              type="primary"
              onClick={handleOpenEditDocumentRequest}
            >
              Resend request
            </IncredableButton>
            <IncredableButton
              type="primary"
              onClick={() => {
                if (!!privilegeId)
                  handleDenyButton(privilegeId, selectedRows?.at(0)?.id || "");
              }}
            >
              Deny
            </IncredableButton>
          </Space>
        </Flex>
      </AntdModal>

      {/*for edit document share request  */}
      <EditPrivilegeDocumentRequestList
        editDocumentRequest={editDocumentRequest}
        handleCloseEditDocumentRequest={handleCloseEditDocumentRequest}
        document={selectedRows?.at(0)}
      />

      {/* for approve  */}
      <AddPrivilegeApproveDocument
        addNewPrivilegeDocumentModal={addNewDocumentModal}
        handleCloseAddNewDocument={handleCloseAddNewDocument}
        document={selectedRows?.at(0)}
      />

      {/* for click the filename */}
      <Modal
        visible={isModalVisible}
        onDismiss={handleModalClose}
        header="Document Request File Preview"
        size="max"
      >
        <div>
          {selectedAttachment && (
            <PDFTronViewer
              attachmentKey={selectedAttachment?.key}
              replaceFile={undefined}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
